import { each, keys, sortBy } from 'lodash'

import { buildBreakpoints } from '../utils'

const breakpoints = {
  desktop: 1920,
  laptop: 1440,
  tablet: 1024,
  tabletPortrait: 820,
  mobile: 640,
  mobilePortrait: 480,
}

export default breakpoints

export const breakpointData = buildBreakpoints(breakpoints)
