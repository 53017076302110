/** @jsx jsx */
import React, { useMemo } from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { get } from 'lodash'

const pageLinksQuery = graphql`
  query {
    aboutPage: allWpPage(
      filter: { template: { templateName: { eq: "About" } } }
      limit: 1
    ) {
      nodes {
        title
        uri
      }
    }
    workPage: allWpPage(
      filter: { template: { templateName: { eq: "Work" } } }
      limit: 1
    ) {
      nodes {
        title
        uri
      }
    }
    contactPage: allWpPage(
      filter: { template: { templateName: { eq: "Contact" } } }
      limit: 1
    ) {
      nodes {
        title
        uri
      }
    }
  }
`

const linkFromPage = (data, key) => {
  const page = get(data, `${key}.nodes.0`)
  return page
    ? {
        title: page.title,
        uri: page.uri,
      }
    : null
}

const usePageLinks = () => {
  const data = useStaticQuery(pageLinksQuery)
  return useMemo(() => {
    return {
      about: linkFromPage(data, 'aboutPage'),
      work: linkFromPage(data, 'workPage'),
      contact: linkFromPage(data, 'contactPage'),
    }
  }, [data])
}

export default usePageLinks
