/** @jsx jsx */
import React, { useState } from 'react'
import { jsx } from 'theme-ui'

import ImageFill from '../../image'

import { formatImage } from '../../../lib/helpers'

const Image = (props) => {
  const { fieldGroupName, image, containedEl } = props

  const _image = formatImage(image)

  return _image ? (
    <ImageFill
      image={_image}
      contain={true}
      containedEl={containedEl}
      gatsbyImageProps={{
        loading: 'eager',
      }}
    />
  ) : null
}

export default Image
