import React, { useMemo } from 'react'
import { isFinite, fill } from 'lodash'

const useArrayOfSize = (count) => {
  return useMemo(() => {
    return isFinite(count) ? fill(Array(count), null) : []
  }, [count])
}

export default useArrayOfSize
