import React from 'react'
import { get } from 'lodash'

import WrappedEl from './src/wrapped-el'

export const wrapRootElement = ({ element }) => {
  return <WrappedEl>{element}</WrappedEl>
}

const transitionDelay = 300

export const shouldUpdateScroll = ({
  routerProps,
  prevRouterProps,
  getSavedScrollPosition,
}) => {
  const location = get(routerProps, 'location')
  const prevLocation = get(prevRouterProps, 'location')

  if (!prevLocation) {
    return false
  }

  const savedPosition = getSavedScrollPosition(location)
  window.setTimeout(
    () => window.scrollTo(...(savedPosition || [0, 0])),
    transitionDelay
  )
  return false
}
