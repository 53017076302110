import React, { useState } from 'react'
import { Provider as AtomProvider } from 'jotai'

import { ColourCycleProvider } from './components/colour-cycle'

export default ({ children }) => {
  const [headerTheme, setHeaderTheme] = useState(null)

  return (
    <AtomProvider>
      <ColourCycleProvider>{children}</ColourCycleProvider>
    </AtomProvider>
  )
}
