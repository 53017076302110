/** @jsx jsx */
import React, { useState, useMemo } from 'react'
import { jsx } from 'theme-ui'
import { useAtom } from 'jotai'
import { css } from '@emotion/react'
import { motion, AnimatePresence } from 'framer-motion'
import { graphql, useStaticQuery } from 'gatsby'
import { get, find } from 'lodash'

import TypeBlock from '../type-block'

import { activeProjectInfoAtom } from './index'

import { settings as animationSettings } from '../../lib/animation'
import { renderHTML } from '../../lib/helpers'

import { fillArea, hardwareAccelerate, scrollY } from '../../styles/css'
import { TextBlock } from '../../styles/els'

const Tagline = (props) => {
  const { project, headerHeight, galleryBottom, ...otherProps } = props

  const tagline = get(project, 'acf.tagline')
  const taglineIsLong = tagline && tagline.length > 90

  return (
    <AnimatePresence>
      {project ? (
        <div
          key={get(project, 'databaseId')}
          sx={{
            position: 'absolute',
            left: 0,
            width: '100%',
            top: headerHeight,
            bottom: galleryBottom,
            pb: ['7px', null, null, '4px'],
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 5,
            pointerEvents: 'none',
            overflow: 'hidden',
          }}
        >
          <div
            sx={{
              width: ['100%', null, null, null, taglineIsLong ? '65%' : '50%'],
              maxWidth: [
                taglineIsLong ? '600px' : '440px',
                null,
                null,
                null,
                'none',
              ],
              textAlign: 'center',
              pl: 3,
              pr: 3,
            }}
          >
            <TypeBlock variant="body4" multiline={true} invert={true}>
              {tagline}
            </TypeBlock>
          </div>
        </div>
      ) : null}
    </AnimatePresence>
  )
}

export default Tagline
