/** @jsx jsx */
import React, { useContext } from 'react'
import { jsx } from 'theme-ui'
import { useAtom } from 'jotai'
import { css } from '@emotion/react'
import { motion, AnimatePresence } from 'framer-motion'
import { graphql, useStaticQuery } from 'gatsby'
import { get, find, fill } from 'lodash'

import { fillArea } from '../../styles/css'
import { FillArea } from '../../styles/els'

import { ColourCycleContext } from './index'

const BgLayer = (props) => {
  const { color, ...otherProps } = props

  return (
    <motion.div
      css={css`
        ${fillArea}
      `}
      sx={{
        opacity: 0.75,
      }}
      style={{
        backgroundColor: color,
      }}
      {...otherProps}
    />
  )
}

const ColourOverlay = React.forwardRef((props, ref) => {
  const { children, ...otherProps } = props

  const color = useContext(ColourCycleContext)

  return (
    <FillArea ref={ref} {...otherProps}>
      {children ? (
        <div sx={{ position: 'relative', zIndex: 2 }}>{children}</div>
      ) : null}
      <BgLayer color={color} sx={{ zIndex: 1 }} />
    </FillArea>
  )
})

export const MotionColourOverlay = motion(ColourOverlay)

const variants = {
  in: {
    opacity: 1,
    transition: {
      duration: 0.4,
    },
  },
  out: {
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
}

export const FadingColourOverlay = (props) => {
  const { hidden, ...otherProps } = props
  return (
    <AnimatePresence>
      {!hidden ? (
        <MotionColourOverlay
          variants={variants}
          initial="out"
          animate="in"
          exit="out"
          {...otherProps}
        />
      ) : null}
    </AnimatePresence>
  )
}

export default ColourOverlay
