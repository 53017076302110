import React from 'react'
import { Global, css } from '@emotion/react'

import reset from './reset'
import { fontFace } from './css'

import CanelaLIWOFF from './typeface/Canela-LightItalic.woff'
import CanelaLIWOFF2 from './typeface/Canela-LightItalic.woff2'

import CanelaRWOFF from './typeface/Canela-Regular.woff'
import CanelaRWOFF2 from './typeface/Canela-Regular.woff2'

import SwissLEOT from './typeface/Swiss721BT-Light.eot'
import SwissLTTF from './typeface/Swiss721BT-Light.ttf'
import SwissLWOFF from './typeface/Swiss721BT-Light.woff'
import SwissLWOFF2 from './typeface/Swiss721BT-Light.woff2'

import SwissREOT from './typeface/Swiss721BT-Roman.eot'
import SwissRTTF from './typeface/Swiss721BT-Roman.ttf'
import SwissRWOFF from './typeface/Swiss721BT-Roman.woff'
import SwissRWOFF2 from './typeface/Swiss721BT-Roman.woff2'

import SwissBEOT from './typeface/Swiss721BT-Bold.eot'
import SwissBTTF from './typeface/Swiss721BT-Bold.ttf'
import SwissBWOFF from './typeface/Swiss721BT-Bold.woff'
import SwissBWOFF2 from './typeface/Swiss721BT-Bold.woff2'

export default (props) => {
  return (
    <Global
      styles={(theme) => css`
        ${reset}

        ${fontFace(
          `'Canela'`,
          {
            woff: CanelaLIWOFF,
            woff2: CanelaLIWOFF2,
          },
          200,
          'italic'
        )}

        ${fontFace(
          `'Canela'`,
          {
            woff: CanelaRWOFF,
            woff2: CanelaRWOFF2,
          },
          300,
          'normal'
        )}

        ${fontFace(
          `'Swiss721BT-Roman'`,
          {
            eot: SwissREOT,
            ttf: SwissRTTF,
            woff: SwissRWOFF,
            woff2: SwissRWOFF2,
          },
          300,
          'normal'
        )}

        ${fontFace(
          `'Swiss721BT-Bold'`,
          {
            eot: SwissBEOT,
            ttf: SwissBTTF,
            woff: SwissBWOFF,
            woff2: SwissBWOFF2,
          },
          500,
          'normal'
        )}

        ${fontFace(
          `'Swiss721BT-Light'`,
          {
            eot: SwissLEOT,
            ttf: SwissLTTF,
            woff: SwissLWOFF,
            woff2: SwissLWOFF2,
          },
          200,
          'normal'
        )}

        html,
        body {
          width: 100%;
        }

        body {
          min-height: 100%;
          overflow-x: hidden;
          overflow-y: visible;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: 'grayscale';

          &,
          * {
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

            &:focus {
              outline: none;
            }
          }
        }

        a {
          color: inherit;
          text-decoration: none;

          &:focus {
            outline: none;
          }
        }
      `}
    />
  )
}
