/** @jsx jsx */
import React from 'react'
import { jsx, useColorMode } from 'theme-ui'
import { atom, useAtom } from 'jotai'
import { Link } from 'gatsby'
import { motion, AnimatePresence } from 'framer-motion'
import { indexOf, sortBy } from 'lodash'

import TypeBlock from '../type-block'

import { getVW } from '../../styles/utils'

import usePageLinks from './use-page-links'

const variants = {
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
}

const pages = ['about', 'work', 'contact']
const orderedPages = sortBy(pages, (v) => {
  return v.length
})

export const menuActiveAtom = atom(false)

const Menu = (props) => {
  const { ...otherProps } = props
  const [menuActive, setMenuActive] = useAtom(menuActiveAtom)
  const pageLinks = usePageLinks()

  return (
    <AnimatePresence>
      {menuActive ? (
        <motion.div
          key="menu"
          variants={variants}
          initial="out"
          animate="in"
          exit="out"
          sx={{
            backgroundColor: 'transparent',
            color: 'text',
            position: 'fixed',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            zIndex: 999,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            px: 4,
            py: '100px',
          }}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()

            setMenuActive(false)
          }}
          {...otherProps}
        >
          {pages.map((_linkKey, _i) => {
            const _link = pageLinks[_linkKey]
            return _link ? (
              <Link
                key={_i}
                to={_link.uri}
                sx={{
                  display: 'block',
                  pt: getVW('29px', 1920),
                  mt: _i === 0 ? getVW('-52px', 1920) : null,
                  position: 'relative',
                }}
                style={{
                  zIndex: indexOf(orderedPages, _linkKey) + 1,
                }}
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                <TypeBlock
                  isInteractive={true}
                  bgInteractive={true}
                  variant="header"
                  paddTop={true}
                >
                  {_link.title}
                </TypeBlock>
              </Link>
            ) : null
          })}
        </motion.div>
      ) : null}
    </AnimatePresence>
  )
}

export default Menu
