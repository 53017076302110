/** @jsx jsx */
import React, { useMemo, useState, useRef, useEffect } from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'
import { isFunction, reject, isNil, isFinite } from 'lodash'
import random from 'canvas-sketch-util/random'
import { motion } from 'framer-motion'

import ImageFill from '../image'

import useLoop from '../../lib/hooks/use-loop'

import { fillArea } from '../../styles/css'

const fadeTime = 0.3

const imageVariants = {
  in: {
    opacity: 1,
    transition: {
      type: 'tween',
      duration: fadeTime,
    },
  },
  out: {
    opacity: 0,
    transition: {
      type: 'tween',
      duration: 0.1,
      delay: fadeTime,
    },
  },
}

const Flick = (props) => {
  const {
    images: _images,
    time,
    minTime,
    maxTime,
    initDelay,
    contain,
    containedEl,
    alignX,
    alignY,
    resolver,
    paused,
    gatsbyImageProps,
    ...otherProps
  } = props
  const [activeIndex, setActiveIndex] = useState(0)
  const { images, imagesCount } = useMemo(() => {
    const __images = reject(
      _images && _images.length > 0
        ? _images.map((_image) => {
            return resolver && isFunction(resolver) ? resolver(_image) : _image
          })
        : [],
      isNil
    )
    return {
      images: __images,
      imagesCount: __images.length,
    }
  }, [_images, resolver])
  const nextTickTime = useRef(null)
  const lastTick = useRef(0)
  const hasInitDelayed = useRef(false)

  const setNextTime = () => {
    let nextTime =
      isFinite(minTime) && isFinite(maxTime)
        ? random.range(minTime, maxTime)
        : isFinite(time)
        ? time
        : 1000

    if (isFinite(initDelay) && !hasInitDelayed.current) {
      nextTime += initDelay
    }

    if (!hasInitDelayed.current) {
      hasInitDelayed.current = true
    }

    nextTickTime.current = nextTime
  }

  useLoop(
    paused
      ? null
      : ({ elapsed }) => {
          if (!isFinite(lastTick.current)) {
            lastTick.current = elapsed
          }

          if (!isFinite(nextTickTime.current)) {
            setNextTime()
          }

          const shouldTick = elapsed - lastTick.current >= nextTickTime.current

          if (shouldTick) {
            let nI = activeIndex + 1
            if (nI >= imagesCount) {
              nI = 0
            }
            lastTick.current = elapsed
            setNextTime()

            setActiveIndex(nI)
          }
        },
    {
      fps: 15,
    },
    [activeIndex]
  )

  useEffect(() => {
    if (!paused) {
      lastTick.current = null
      nextTickTime.current = null
      hasInitDelayed.current = false
    }
  }, [paused])

  return images && images.length > 0 ? (
    <div
      css={css`
        ${fillArea}
      `}
      {...otherProps}
    >
      {images.map((_image, _i) => {
        const _isActive = _i === activeIndex
        const _zIndex =
          ((_i - activeIndex + (imagesCount - 1)) % imagesCount) + 1
        return (
          <motion.div
            key={_i}
            css={css`
              ${fillArea}
            `}
            variants={imageVariants}
            animate={_isActive ? 'in' : 'out'}
            style={{
              zIndex: _zIndex,
            }}
          >
            <ImageFill
              key={_i}
              image={_image}
              contain={contain}
              containedEl={containedEl}
              alignX={alignX}
              alignY={alignY}
              gatsbyImageProps={gatsbyImageProps}
            />
          </motion.div>
        )
      })}
    </div>
  ) : null
}

export default Flick
