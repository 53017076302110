/** @jsx jsx */
import React from 'react'
import { jsx, useColorMode } from 'theme-ui'

import { BGImage } from '../../styles/els'

import LogoW from './logo-white.svg'
import LogoB from './logo-black.svg'
import { getColorFromColorMode } from '../../styles/utils'

const Logo = (props) => {
  const { sx, color, ...otherProps } = props

  const [_colorMode] = useColorMode()
  const colorMode = getColorFromColorMode()

  return (
    <BGImage
      image={
        (!color && colorMode === 'dark') || color === 'light' ? LogoW : LogoB
      }
      ratio={22 / 220}
      {...otherProps}
    />
  )
}

export default Logo
