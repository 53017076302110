/** @jsx jsx */
import React, { useMemo } from 'react'
import { jsx } from 'theme-ui'
import { useAtom, useSetAtom } from 'jotai'
import { findIndex, get, isFinite } from 'lodash'
import { motion, AnimatePresence } from 'framer-motion'
import { Link } from 'gatsby'

import Icon from '../icon'
import TypeBlock from '../type-block'

import { settings as animationSettings } from '../../lib/animation'

import { isMutedAtom, projectInfoActiveAtom, slideHasSoundAtom } from './index'
import TypeBlockWrapper from '../type-block'
import { useThemeUISetValue } from '../../styles/utils'

const titleVariants = {
  initial: {
    y: '100%',
    opacity: 0,
  },
  in: {
    y: '0px',
    opacity: 1,
    transition: {
      type: 'tween',
      duration: 0.6,
      ease: animationSettings.easings.easeOut,
    },
  },
  out: {
    y: '-100%',
    opacity: 0,
    transition: {
      type: 'tween',
      duration: 0.4,
      ease: animationSettings.easings.easeIn,
    },
  },
}

const muteToggleVariants = {
  out: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  faded: {
    opacity: 0.5,
  },
}

const Footer = (props) => {
  const { project, ...otherProps } = props

  const [slideHasSound] = useAtom(slideHasSoundAtom)
  const [isMuted, setIsMuted] = useAtom(isMutedAtom)
  const setProjectInfoActive = useSetAtom(projectInfoActiveAtom)

  const outerPadding = useThemeUISetValue('outerPadding')

  const projectName = project ? get(project, 'title') : null
  const projectTitle = project ? get(project, 'acf.title') : null

  return (
    <div
      sx={{
        position: 'absolute',
        left: 0,
        bottom: ['54px', null, 0],
        width: '100%',
        p: outerPadding,
        display: 'flex',
        flexDirection: ['column', null, 'row'],
        justifyContent: ['stretch', null, 'space-between'],
        alignItems: ['stretch', null, 'flex-end'],

        '& > div': {
          mb: [null, null, '-4px'],
          pt: '1px',
          pb: '1px',
        },
      }}
      {...otherProps}
    >
      <div
        sx={{
          position: 'relative',
          display: 'block',
          height: 'auto',
          overflow: 'hidden',
          flex: 1,

          '&:before': {
            content: '"\\00a0 "',
            display: 'block',
          },
        }}
      >
        <AnimatePresence exitBeforeEnter>
          {project && (projectName || projectTitle) ? (
            <motion.h2
              key={project.databaseId}
              variants={titleVariants}
              initial="initial"
              animate="in"
              exit="out"
              sx={{
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                variant: 'text.body',
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: ['space-between', 'flex-start'],
              }}
            >
              {projectName ? (
                <span
                  sx={{
                    variant: 'text.bodyBold',
                    pr: '4px',
                  }}
                >
                  {projectName}
                </span>
              ) : null}
              {projectTitle ? (
                <span
                  sx={{
                    display: ['none', 'inline'],
                  }}
                >
                  {projectTitle}
                </span>
              ) : null}
              <AnimatePresence>
                {slideHasSound ? (
                  <motion.div
                    key="muteToggle"
                    variants={muteToggleVariants}
                    initial="out"
                    animate={isMuted ? 'faded' : 'in'}
                    exit="out"
                    transition={{
                      type: 'tween',
                      duration: 0.3,
                      ease: animationSettings.easings.easeInOut,
                    }}
                    sx={{
                      pl: '10px',
                    }}
                  >
                    <Icon
                      icon={isMuted ? 'soundOff' : 'soundOn'}
                      sx={{
                        width: '30px',
                        mt: '-6px',

                        '&:hover': {
                          cursor: 'pointer',
                        },
                      }}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()

                        setIsMuted(!isMuted)
                      }}
                    />
                  </motion.div>
                ) : null}
              </AnimatePresence>
            </motion.h2>
          ) : null}
        </AnimatePresence>
      </div>
      <Link
        to="/work/"
        sx={{
          position: 'absolute',
          left: '50%',
          top: [null, null, '23px'],
          bottom: ['-40px', null, 'auto'],
          width: '54px',
          p: '15px',
          ml: '-27px',
          opacity: 0.5,
          transition: 'opacity 0.3s ease-in-out',

          '&:hover': {
            opacity: 1,
          },
        }}
      >
        <Icon icon="gridOutline" sx={{ width: '100%' }} />
      </Link>
      {project ? (
        <div sx={{ whiteSpace: 'nowrap' }}>
          <TypeBlock
            variant="body"
            bg={true}
            transparent={true}
            isInteractive={true}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()

              setProjectInfoActive(true)
            }}
          >
            About the project
          </TypeBlock>
        </div>
      ) : null}
    </div>
  )
}

export default Footer
