import React, { useMemo, useState, useEffect, useRef } from 'react'
import useDimensions from 'react-cool-dimensions'
import { useMotionValue, useTransform, animate } from 'framer-motion'
import { inRange, isBoolean, isFinite, isFunction, range } from 'lodash'

import { settings as animationSettings } from '../animation'

const wrappedIndex = (index, count) => {
  if (count <= 0 || !isFinite(count)) {
    return null
  }

  let jI = index
  if (jI < 0) {
    jI = count + jI
  } else if (jI >= count) {
    jI = jI % count
  }
  return jI
}

const floodSlides = (activeIndex, count, buffer) => {
  const slides = range(buffer * -1, buffer + 1)
  return slides.map((_i) => {
    return wrappedIndex(activeIndex + _i, count)
  })
}

const limitIndex = (i, count) => {
  return Math.max(0, Math.min(i, count - 1))
}

const useCarousel = (props = {}) => {
  const {
    buffer: _buffer,
    trim: _trim,
    wrap: _wrap,
    activeIndex: _activeIndex,
    targetIndex: _targetIndex,
    setActiveIndex: _setActiveIndex,
    setTargetIndex: _setTargetIndex,
    count: _count,
    auto: _auto,
    listenToArrowKeys,
  } = props

  const [__activeIndex, __setActiveIndex] = useState(0)
  const [__targetIndex, __setTargetIndex] = useState(0)

  const count = isFinite(_count) ? _count : 0
  const trim = count <= 2 ? false : isBoolean(_trim) ? _trim : true
  const buffer = isFinite(_buffer) && _buffer >= 0 ? _buffer : trim ? 1 : 0
  const useInternalActiveIndex =
    !isFinite(_activeIndex) && !isFunction(_setActiveIndex)
  const activeIndex = useInternalActiveIndex ? __activeIndex : _activeIndex
  const useInternalTargetIndex =
    !isFinite(_targetIndex) && !isFunction(_setTargetIndex)
  const targetIndex = useInternalTargetIndex ? __targetIndex : _targetIndex
  const auto = isBoolean(_auto) ? _auto : false

  const [visibleMap, visibleCount] = useMemo(() => {
    const visibleSlides = trim
      ? floodSlides(activeIndex, count, buffer)
      : range(0, count)
    return [visibleSlides, visibleSlides.length]
  }, [activeIndex, count, buffer, trim])

  const { observe, width } = useDimensions()

  const tORef = useRef(null)

  const offsetPercentage = useMotionValue(0)
  const xOffset = useTransform(offsetPercentage, (v) => {
    return (v + buffer) * width * -1
  })

  const touchRef = useRef({ start: null, to: null, active: false })
  const [isGrabbed, setIsGrabbed] = useState(false)

  const hasDimensions = isFinite(width) && width > 0
  const trayWidth = hasDimensions ? Math.ceil(width * visibleCount) : null

  const setActiveIndex = (i) => {
    if (useInternalActiveIndex) {
      __setActiveIndex(i)
    } else {
      _setActiveIndex(i)
    }
  }

  const setTargetIndex = (i) => {
    if (useInternalTargetIndex) {
      __setTargetIndex(i)
    } else {
      _setTargetIndex(i)
    }
  }

  const animateTo = (i, _opts = {}) => {
    if (offsetPercentage.isAnimating()) {
      return null
    }

    const opts = {
      type: _opts.type ? _opts.type : 'tween',
      duration: isFinite(_opts.duration) ? _opts.duration : 0.9,
    }
    if (opts.type === 'tween') {
      opts.ease = animationSettings.easings.easeInOut
    } else if (opts.type === 'spring') {
      opts.bounce = 0
    }

    setTargetIndex(
      trim ? wrappedIndex(activeIndex + i, count) : limitIndex(i, count)
    )

    animate(offsetPercentage, trim ? i : limitIndex(i, count), {
      ...opts,
      onComplete: () => {
        const newIndex = activeIndex + offsetPercentage.get()
        setActiveIndex(
          trim ? wrappedIndex(newIndex, count) : limitIndex(i, count)
        )
      },
    })
  }

  const toIndex = (i = 0, opts) => {
    animateTo(i, {
      duration: 1.2,
      ...opts,
    })
  }

  const shiftIndex = (inc = 1, opts) => {
    const _index = offsetPercentage.get()
    toIndex(Math.round(_index) + inc, opts)
  }

  const keyHandler = (e) => {
    switch (e.keyCode) {
      case 37:
      case 38:
        shiftIndex(-1)
        break
      case 39:
      case 40:
        shiftIndex()
        break
    }
  }

  const cancelTO = () => {
    if (tORef.current) {
      clearTimeout(tORef.current)
      tORef.current = null
    }
  }

  const setTO = () => {
    tORef.current = setTimeout(() => {
      shiftIndex(1)
    }, 6400)
  }

  const onGrabSuccess = (dist) => {
    touchRef.current.active = false

    shiftIndex(dist < 0 ? -1 : 1, {
      duration: 0.8,
      type: 'spring',
    })
  }

  const onGrabDown = (x) => {
    if (offsetPercentage.isAnimating()) {
      return
    }
    touchRef.current = {
      start: x,
      to: x,
      active: true,
    }
    setIsGrabbed(true)
  }

  const onGrabMove = (x) => {
    if (!touchRef.current.active) {
      return
    }

    touchRef.current.to = x
    const perc = offsetPercentage.get()
    const dist = touchRef.current.start - x
    const distPerc = dist / width
    if (Math.abs(dist) > 150 || Math.abs(distPerc) >= 0.4) {
      onGrabSuccess(dist)
    } else {
      offsetPercentage.set(trim ? distPerc : activeIndex + distPerc)
    }
  }

  const onGrabRelease = () => {
    const dist = touchRef.current.start - touchRef.current.to
    const snapBack = Math.abs(dist) <= 50

    shiftIndex(snapBack ? 0 : dist < 0 ? -1 : 1, {
      type: 'spring',
      duration: snapBack ? 0.3 : 0.9,
    })

    setIsGrabbed(false)
    touchRef.current = {
      start: null,
      to: null,
      active: false,
    }
  }

  const onMouseMove = (e) => {}

  const touchMoveHandler = (e) => {
    e.preventDefault()
    onGrabMove(e.touches[0].clientX)
  }

  const touchEndHandler = () => {
    onGrabRelease()
  }

  const dragEvents = {
    /*onMouseDown: (e) => {
      console.log(e)
    },*/
    onTouchStart: (e) => {
      onGrabDown(e.touches[0].clientX)
    },
  }

  useEffect(() => {
    if (listenToArrowKeys) {
      window.addEventListener('keydown', keyHandler)
    }
    return () => {
      window.removeEventListener('keydown', keyHandler)
    }
  }, [listenToArrowKeys, activeIndex])

  useEffect(() => {
    if (auto) {
      setTO()
    }
    return () => {
      cancelTO()
    }
  }, [auto, activeIndex])

  useEffect(() => {
    if (isGrabbed) {
      window.addEventListener('touchmove', touchMoveHandler, { passive: false })
      window.addEventListener('touchend', touchEndHandler)
      window.addEventListener('touchcancel', touchEndHandler)
    }
    return () => {
      window.removeEventListener('touchmove', touchMoveHandler)
      window.removeEventListener('touchend', touchEndHandler)
      window.removeEventListener('touchcancel', touchEndHandler)
    }
  }, [isGrabbed, activeIndex])

  useEffect(() => {
    if (trim) {
      offsetPercentage.set(0)
    }
  }, [trim, activeIndex])

  return {
    refToObserve: observe,
    width,
    trayWidth,
    hasDimensions,
    visibleMap,
    visibleCount,
    offsetPercentage,
    xOffset,
    activeIndex,
    targetIndex,
    shiftIndex,
    toIndex,
    dragEvents,
  }
}

export default useCarousel
