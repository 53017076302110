import React, { useState, useRef } from 'react'
import { useMotionValue } from 'framer-motion'
import Color from 'canvas-sketch-util/color'

import useLoop from '../../lib/hooks/use-loop'

import ColourOverlay, {
  FadingColourOverlay,
  MotionColourOverlay,
} from './overlay'

const hueToColor = (hue = 0) => {
  return Color.parse({ hsl: [hue, 100, 50] }).hex
}

export const ColourCycleContext = React.createContext()

export const ColourCycleProvider = (props) => {
  const { children } = props

  const hueLength = 360
  const hueInc = 0.2
  const hueOffset = useRef(Math.floor(Math.random() * hueLength))

  const color = useMotionValue(hueToColor(hueOffset.current))

  useLoop(({ frame }) => {
    const _hue = (hueOffset.current + frame * hueInc) % hueLength
    color.set(hueToColor(_hue))
  })

  return (
    <ColourCycleContext.Provider value={color}>
      {children}
    </ColourCycleContext.Provider>
  )
}

export { ColourOverlay, FadingColourOverlay, MotionColourOverlay }
