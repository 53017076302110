/** @jsx jsx */
import React, { useMemo } from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { filter, get, isFunction } from 'lodash'

const allWorkQuery = graphql`
  query {
    work: allWpWork(sort: { menuOrder: ASC }) {
      nodes {
        databaseId
        uri
        title
        acf {
          previewOnly
          title
          subheadline
          tagline
          textContent
          splashImages {
            __typename
            ... on WpWork_Acf_SplashImages_SplashImage {
              image {
                ...ThumbnailImage
              }
            }
            ... on WpWork_Acf_SplashImages_SplashFlick {
              logo {
                ...ThumbnailImage
              }
              images {
                image {
                  ...ThumbnailImage
                }
              }
            }
            ... on WpWork_Acf_SplashImages_SplashVideo {
              placeholder {
                ...PlaceholderThumbnailImage
              }
              vimeoId
            }
          }
          images {
            __typename
            ... on WpWork_Acf_Images_Flick {
              images {
                image {
                  ...FullsizeImage
                }
              }
            }
            ... on WpWork_Acf_Images_FlickSplit {
              leftImages {
                image {
                  ...PortraitImage
                }
              }
              rightImages {
                image {
                  ...PortraitImage
                }
              }
            }
            ... on WpWork_Acf_Images_Image {
              image {
                ...FullsizeImage
              }
            }
            ... on WpWork_Acf_Images_Video {
              hasSound
              placeholder {
                ...PlaceholderImage
              }
              vimeoId
            }
          }
        }
      }
    }
  }
`

const useAllWork = () => {
  return useStaticQuery(allWorkQuery)
}

export const useAllWorkFiltered = (_filter = null, deps = []) => {
  const workData = useAllWork()
  return useMemo(() => {
    const workNodes = get(workData, 'work.nodes')
    return _filter ? filter(workNodes, _filter) : workNodes
  }, [workData, ...deps])
}

export const showPreview = () => {
  return typeof window !== 'undefined' && window.location.hash === '#preview'
}

export const useIsPreviewUrl = () => {
  return useMemo(showPreview, [])
}

export const useAllWorkPreviewFilter = () => {
  const showPreview = useIsPreviewUrl()
  return useAllWorkFiltered(
    showPreview
      ? null
      : (n) => {
          return !get(n, 'acf.previewOnly')
        },
    [showPreview]
  )
}

export default useAllWork
