/** @jsx jsx */
import React, { useState } from 'react'
import { jsx } from 'theme-ui'
import { get } from 'lodash'

import FlickComponent from '../../flick'
import ImageFill from '../../image'

import { flickImageResolver } from '../../../lib/helpers'

const Flick = (props) => {
  const { fieldGroupName, images, isActive, containedEl } = props

  return !images || images.length <= 0 ? null : images.length === 1 ? (
    <ImageFill
      image={flickImageResolver(images[0])}
      contain={true}
      containedEl={containedEl}
      gatsbyImageProps={{ loading: 'eager' }}
    />
  ) : (
    <FlickComponent
      images={images}
      resolver={flickImageResolver}
      contain={true}
      containedEl={containedEl}
      paused={!isActive}
      time={1200}
      gatsbyImageProps={{ loading: 'eager' }}
    />
  )
}

export default Flick
