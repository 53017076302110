/** @jsx jsx */
import React, { useState, useMemo } from 'react'
import { jsx } from 'theme-ui'
import { useAtom } from 'jotai'
import { css } from '@emotion/react'
import { motion, AnimatePresence } from 'framer-motion'
import { graphql, useStaticQuery } from 'gatsby'
import { get, find } from 'lodash'

import Hamburger from '../hamburger'
import TypeBlock from '../type-block'
import {
  projectInfoActiveAtom,
  activeSlideAtom,
  inviewSlidesAtom,
  parseProjectData,
  projectForSlideIndex,
} from '../work-gallery'
import useAllWork from '../work-gallery/use-all-work'

import { settings as animationSettings } from '../../lib/animation'
import { renderHTML } from '../../lib/helpers'

import { fillArea, hardwareAccelerate, scrollY } from '../../styles/css'
import { TextBlock } from '../../styles/els'
import { getVW, useThemeUISetValue } from '../../styles/utils'

const infoPanelVariants = {
  out: {
    x: '100%',
    transition: {
      type: 'tween',
      duration: 0.8,
      ease: animationSettings.easings.easeIn,
    },
  },
  in: {
    x: '0px',
    transition: {
      type: 'tween',
      duration: 1.2,
      ease: animationSettings.easings.easeInOut,
    },
  },
}

const InfoText = (props) => {
  const { data, ...otherProps } = props

  const title = get(data, 'title')
  const subheadline = get(data, 'acf.subheadline')
  const tagline = get(data, 'acf.tagline')
  const content = get(data, 'acf.textContent')

  return (
    <TextBlock {...otherProps}>
      {title ? <h1>{renderHTML(title)}</h1> : null}
      {subheadline ? <h2>{renderHTML(subheadline)}</h2> : null}
      {tagline ? (
        <TypeBlock
          variant="body4"
          multiline={true}
          sx={{ pb: ['26px', '35px'] }}
        >
          {tagline}
        </TypeBlock>
      ) : null}
      {content ? renderHTML(content) : null}
    </TextBlock>
  )
}

const Info = (props) => {
  const { ...otherProps } = props
  const workData = useAllWork()

  const [projectInfoActive, setProjectInfoActive] = useAtom(
    projectInfoActiveAtom
  )
  const [activeSlide] = useAtom(activeSlideAtom)
  const [inviewSlides] = useAtom(inviewSlidesAtom)

  const outerPadding = useThemeUISetValue('outerPadding')
  const headerHeight = useThemeUISetValue('headerHeight')

  const project = useMemo(() => {
    if (!projectInfoActive) {
      return null
    }

    const [imageData, projectData] = parseProjectData(
      get(workData, 'work.nodes')
    )
    return projectForSlideIndex(
      activeSlide,
      inviewSlides,
      imageData,
      projectData
    )
  }, [activeSlide, inviewSlides, workData, projectInfoActive])

  const projectInfoIsActive = projectInfoActive && project

  const baseZIndex = 1000

  const onClickToClose = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setProjectInfoActive(false)
  }

  return (
    <React.Fragment>
      {projectInfoIsActive ? (
        <div
          sx={{
            zIndex: baseZIndex + 10,

            '&:hover': {
              cursor: 'pointer',
            },
          }}
          css={css`
            ${fillArea}
          `}
          onClick={onClickToClose}
        />
      ) : null}
      <AnimatePresence exitBeforeEnter>
        {projectInfoIsActive ? (
          <motion.div
            key={project.databaseId}
            variants={infoPanelVariants}
            initial="out"
            animate="in"
            exit="out"
            sx={{
              position: 'fixed',
              right: 0,
              top: 0,
              height: '100%',
              width: ['100%', null, '75%', '50%'],
              zIndex: baseZIndex + 15,
            }}
            css={css`
              ${hardwareAccelerate}
            `}
          >
            <div
              sx={{
                zIndex: 1,
                backgroundColor: 'background',
                opacity: 0.92,
              }}
              css={css`
                ${fillArea}
              `}
            />
            <Hamburger
              sx={{
                position: 'absolute',
                right: 0,
                top: 0,
                width: ['70px', null, '110px'],
                p: outerPadding,
                zIndex: 3,

                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              open={true}
              thin={true}
              onClick={onClickToClose}
            />
            <div
              sx={{
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                bottom: 0,
                zIndex: 2,
              }}
              css={css`
                ${scrollY}
              `}
            >
              <InfoText
                sx={{
                  px: outerPadding,
                  py: headerHeight,
                  maxWidth: [null, null, null, null, getVW(820, 1920)],
                }}
                data={project}
              />
            </div>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </React.Fragment>
  )
}

export default Info
