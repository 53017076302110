/** @jsx jsx */
import React, { useState } from 'react'
import { get, jsx } from 'theme-ui'

import FlickComponent from '../../flick'
import ImageFill from '../../image'

import { flickImageResolver } from '../../../lib/helpers'

const tickTime = 1600

const SplitSide = (props) => {
  const { images, side, delay, isActive, containedEl } = props

  const alignRight = side === 'right'
  const alignX = alignRight ? 'left' : 'right'

  return (
    <div
      sx={{
        position: 'absolute',
        left: !alignRight ? 0 : null,
        right: alignRight ? 0 : null,
        top: 0,
        width: '50%',
        height: '100%',
      }}
    >
      {!images || images.length <= 0 ? null : images.length === 1 ? (
        <ImageFill
          image={flickImageResolver(images[0])}
          contain={true}
          containedEl={containedEl}
          alignX={alignX}
          gatsbyImageProps={{ loading: 'eager' }}
        />
      ) : (
        <FlickComponent
          images={images}
          resolver={flickImageResolver}
          contain={true}
          containedEl={containedEl}
          alignX={alignX}
          time={tickTime}
          initDelay={delay ? tickTime * 0.5 : null}
          paused={!isActive}
          gatsbyImageProps={{ loading: 'eager' }}
        />
      )}
    </div>
  )
}

const hasImages = (images) => {
  return images && images.length > 0
}

const FlickSplit = (props) => {
  const { fieldGroupName, leftImages, rightImages, isActive, containedEl } =
    props
  const [delaySide] = useState(Math.random() >= 0.5 ? 'left' : 'right')

  const hasLeft = hasImages(leftImages)
  const hasRight = hasImages(rightImages)
  const hasBoth = hasLeft && hasRight

  return (
    <React.Fragment>
      {hasLeft ? (
        <SplitSide
          side="left"
          images={leftImages}
          isActive={isActive}
          containedEl={containedEl}
          delay={hasBoth && delaySide === 'left'}
        />
      ) : null}
      {hasRight ? (
        <SplitSide
          side="right"
          images={rightImages}
          isActive={isActive}
          containedEl={containedEl}
          delay={hasBoth && delaySide === 'right'}
        />
      ) : null}
    </React.Fragment>
  )
}

export default FlickSplit
