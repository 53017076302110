/** @jsx jsx */
import React, { useContext, useMemo } from 'react'
import { jsx, useThemeUI, useColorMode } from 'theme-ui'
import { Link } from 'gatsby'
import { atom, useAtom } from 'jotai'
import { AnimatePresence, motion } from 'framer-motion'

import Breadcrumbs from '../breadcrumbs'
import Hamburger from '../hamburger'
import Logo from '../logo'
import { menuActiveAtom } from '../menu'

import {
  getColorFromColorMode,
  getColorMode,
  useThemeUISetValue,
  mathOnValueFromTheme,
  mathOnThemeValue,
} from '../../styles/utils'

export const HeaderThemeContext = React.createContext({
  headerTheme: null,
  setHeaderTheme: () => {},
})

export const headerForceColorModeAtom = atom(null)
export const headerIsBlockedAtom = atom(false)

const variants = {
  in: {
    opacity: 1,
    transition: {
      type: 'tween',
      duration: 0.6,
    },
  },
  out: {
    opacity: 0,
    transition: {
      type: 'tween',
      duration: 0.1,
    },
  },
}

export default (props) => {
  const [menuActive, setMenuActive] = useAtom(menuActiveAtom)
  const { theme } = useThemeUI()
  const [_colorMode, setColorMode] = useColorMode()
  const [headerIsBlocked] = useAtom(headerIsBlockedAtom)
  const [headerForceColorMode] = useAtom(headerForceColorModeAtom)
  const headerHeight = useThemeUISetValue('headerHeight')
  const outerPadding = useThemeUISetValue('outerPadding')

  const colorMode = headerForceColorMode ? headerForceColorMode : _colorMode

  const modes = ['dark', 'light']
  const activeColorMode = getColorMode(colorMode)

  const color = activeColorMode === 'light' ? 'dark' : 'light'
  const bgColor = activeColorMode === 'light' ? 'light' : 'dark'
  const bg = getColorFromColorMode('background', bgColor, theme)

  return (
    <header
      sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        zIndex: 1000,
      }}
    >
      <Hamburger
        color={color}
        open={menuActive}
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: ['70px', null, '110px'],
          p: outerPadding,
          zIndex: 5,

          '&:hover': {
            cursor: 'pointer',
          },
        }}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()

          setMenuActive(!menuActive)
        }}
      />
      <Breadcrumbs
        sx={{
          position: 'absolute',
          right: 0,
          top: 0,
          pr: outerPadding,
          pt: ['23px', null, '43px'],
          mr: '-2px',
          zIndex: 5,
        }}
        count={modes.length}
        styles={modes.map((_mode, _i) => {
          const background = getColorFromColorMode('background', _mode, theme)
          const text = getColorFromColorMode('text', _mode, theme)
          const isActive = _mode === activeColorMode

          return {
            border: isActive ? text : background,
            background,
          }
        })}
        onBreadcrumbClick={(i) => {
          const _mode = modes[i]
          setColorMode(_mode)
        }}
      />
      <Link to="/">
        <Logo
          color={color}
          sx={{
            position: 'absolute',
            left: '50%',
            top: ['22px', 2, 3],
            width: ['160px', '210px'],
            transform: 'translate3d(-50%,0,0)',
            zIndex: 5,
          }}
        />
      </Link>
      <AnimatePresence>
        {headerIsBlocked ? (
          <motion.div
            variants={variants}
            initial="out"
            animate="in"
            exit="out"
            sx={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: headerHeight,
              backgroundColor: bg,
            }}
          />
        ) : null}
      </AnimatePresence>
    </header>
  )
}
