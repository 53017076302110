import {
  buildTypeObject,
  buildTypeVariants,
  adobeSpacing,
  getVW,
  mapVW,
} from '../utils'

const sansFallback = `'Helvetica Neue', Helvetica, Arial, sans-serif`
const serifFallback = `Times, serif`

const styles = {
  swiss: {
    font: `'Swiss721BT-Roman', ${sansFallback}`,
    weight: 300,
    style: 'normal',
  },
  swissLight: {
    font: `'Swiss721BT-Light', ${sansFallback}`,
    weight: 200,
    style: 'normal',
  },
  swissBold: {
    font: `'Swiss721BT-Bold', ${sansFallback}`,
    weight: 500,
    style: 'normal',
  },
  canelaRegular: {
    font: `'Canela', ${serifFallback}`,
    weight: 300,
    style: 'normal',
  },
  canelaLightItalic: {
    font: `'Canela', ${serifFallback}`,
    weight: 200,
    style: 'italic',
  },
}

const sans_26 = ['26px', '30px']
const sans_32 = ['32px', '40px']
const sans_64 = ['64px', '76px']
const sans_100 = ['100px', '92px']
const sans_240 = ['240px', '225px']
const canela_52 = ['52px', '54px']
const canela_100 = ['100px', '104px']

const sizes = {
  sans_14: ['14px', '17px', '0px'],
  sans_16: ['16px', '21px', '0px'],
  sans_21: ['21px', '27px', '0px'],
  sans_26r: [...mapVW(sans_26, 1920), '0px'],
  sans_26: [...sans_26, '0px'],
  sans_32r: [...mapVW(sans_32, 1920), '0px'],
  sans_32: [...sans_32, '0px'],
  sans_64r: [...mapVW(sans_64, 1920), '0px'],
  sans_64: [...sans_64, '0px'],
  sans_100r: [...mapVW(sans_100, 480), '0px'],
  sans_128: ['128px', '120px', '0px'],
  sans_240r: [...mapVW(sans_240, 1920), '0px'],
  sans_240: [...sans_240, '0px'],
  canela_52r: [...mapVW(canela_52, 820), adobeSpacing(10)],
  canela_100r: [...mapVW(canela_100, 1920), adobeSpacing(10)],
  canela_100: [...canela_100, adobeSpacing(10)],
}

const rSizes = {
  body: 'sans_14',
  body2: ['sans_16', 'sans_21', 'sans_16', null, 'sans_26r', null, 'sans_26'],
  body3: ['sans_16', 'sans_21', null, null, 'sans_32r', null, 'sans_32'],
  body4: ['sans_26', 'sans_32', null, null, 'sans_64r', null, 'sans_64'],
  header: ['sans_100r', null, 'sans_128', null, 'sans_240r', null, 'sans_240'],
  headline: ['canela_52r', null, null, 'canela_100r', null, null, 'canela_100'],
}

const details = {
  default: {
    textTransform: 'none',
  },
  caps: {
    textTransform: 'uppercase',
  },
  lower: {
    textTransform: 'lowercase',
  },
}

const variants = {
  body: ['swiss', rSizes['body'], 'default'],
  bodyBold: ['swissBold', rSizes['body'], 'default'],
  body2: ['swiss', rSizes['body2'], 'default'],
  body2Bold: ['swissBold', rSizes['body2'], 'default'],
  body3: ['swiss', rSizes['body3'], 'default'],
  body3Bold: ['swissBold', rSizes['body3'], 'default'],
  body4: ['swissLight', rSizes['body4'], 'default'],
  header: ['swiss', rSizes['header'], 'lower'],
  headlineCaps: ['canelaRegular', rSizes['headline'], 'caps'],
  headlineItalic: ['canelaLightItalic', rSizes['headline'], 'default'],
}

export const typeData = buildTypeObject(styles, sizes)
export const typeVariants = buildTypeVariants(variants, styles, sizes, details)

//[0, 480, 640, 820, 1024, 1440, 1920]
