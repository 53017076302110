/** @jsx jsx */
import React, { useContext, useState, useMemo } from 'react'
import { jsx, useThemeUI } from 'theme-ui'
import { motion } from 'framer-motion'
import { SplitText } from '@cyriacbr/react-split-text'
import { math, stripUnit } from 'polished'
import { get, isArray, map } from 'lodash'

import { ColourCycleContext } from '../colour-cycle'

import {
  getVW,
  mapVW,
  mathOnThemeValue,
  mathOnTypeVariant,
  mathOnValueFromTheme,
  valueFromTheme,
  fnOnThemeValue,
} from '../../styles/utils'

const fallbackVariant = (_variant) => {
  return _variant ? _variant : 'headlineCaps'
}

const o_sans_26 = ['1px', '-4px', '-3px', '-4px']
const o_sans_32 = ['1px', '-4px', '-6px', '-4px']
const o_sans_64 = ['4px', '-6px', '-7px', '-7px']
const o_sans_240 = ['30px', '-42px', '2px', '-42px']
const o_canela_100 = ['-12px', '-14px', '0px', '-14px']
const wa_canela_100 = [null, null, '-12px', null]

const offsetMap = {
  sans_14: ['-1px', '-3px', '-3px', '-3px'],
  sans_16: ['-1px', '-3px', '-3px', '-3px'],
  sans_21: ['-1px', '-4px', '-3px', '-4px'],
  sans_26r: mapVW(o_sans_26, 1920),
  sans_26: o_sans_26,
  sans_32r: mapVW(o_sans_32, 1920),
  sans_32: o_sans_32,
  sans_64r: mapVW(o_sans_64, 1920),
  sans_64: o_sans_64,
  sans_100r: mapVW(['12px', '-16px', '1px', '-16px'], 480),
  sans_128: ['16px', '-22px', '1px', '-22px'],
  sans_240r: mapVW(o_sans_240, 1920),
  sans_240: o_sans_240,
  canela_52r: mapVW(['-10px', '-10px', '-1px', '-10px'], 820),
  canela_100r: mapVW(o_canela_100, 1920),
  canela_100: o_canela_100,
}

const weightAdjustmentMap = {
  headlineItalic: {
    canela_52r: mapVW([null, null, '-5px', null], 820),
    canela_100r: mapVW(wa_canela_100, 1920),
    canela_100: wa_canela_100,
  },
}

const mapOffsets = (offsets, index) => {
  return fnOnThemeValue(offsets, (v) => {
    return v && isArray(v) ? v[index] : v ? v : null
  })
}

export const useOffsetsForVariant = (_variant) => {
  const { theme } = useThemeUI()
  const variant = fallbackVariant(_variant)

  return useMemo(() => {
    const fontSize = valueFromTheme(`text.${variant}`, 'fontSize', theme)
    const offsetValues = fnOnThemeValue(fontSize, (_v) => {
      const _offsets = get(offsetMap, _v)
      const _adjustments = get(weightAdjustmentMap, `${variant}.${_v}`)
      return _offsets &&
        _adjustments &&
        isArray(_offsets) &&
        isArray(_adjustments)
        ? _offsets.map((_o, _i) => {
            const _a = _adjustments[_i]
            return _o && _a ? math(`${_o} + ${_a}`) : _o
          })
        : _offsets
        ? _offsets
        : null
    })
    const offsets = isArray(fontSize) ? offsetValues : [offsetValues]
    return {
      top: mapOffsets(offsets, 0),
      right: mapOffsets(offsets, 1),
      bottom: mapOffsets(offsets, 2),
      left: mapOffsets(offsets, 3),
    }
  }, [theme, variant])
}

const TypeTextLine = (props) => {
  const { children, spanProps, bgProps, color, bg, variant } = props

  return (
    <div
      sx={{
        position: 'relative',
        display: 'inline',
        whiteSpace: 'nowrap',
      }}
    >
      {color ? (
        <motion.span {...spanProps} style={{ color }}>
          {children}
        </motion.span>
      ) : (
        <span {...spanProps}>{children}</span>
      )}
      {bg ? (
        <motion.div {...bgProps} style={{ backgroundColor: bg }} />
      ) : (
        <div {...bgProps} />
      )}
    </div>
  )
}

const TypeBlockColor = (props) => {
  const { children, bg, ...otherProps } = props

  const color = useContext(ColourCycleContext)

  return (
    <TypeBlock
      color={!bg ? color : null}
      bg={bg ? color : null}
      {...otherProps}
    >
      {children}
    </TypeBlock>
  )
}

const TypeBlock = (props) => {
  const {
    children,
    color,
    bg,
    invert,
    variant: _variant,
    transparent,
    isInteractive,
    multiline,
    paddTop,
    ...otherProps
  } = props

  const isTransparent = (transparent && !bg) || invert

  const variant = fallbackVariant(_variant)
  const offsets = useOffsetsForVariant(variant)
  const paddingTop = useMemo(() => {
    return paddTop
      ? mathOnThemeValue(offsets.top, (_v) => {
          return _v < 0 ? _v * -1 : null
        })
      : null
  }, [paddTop, offsets])

  const spanProps = { sx: { position: 'relative', zIndex: 5 } }
  const bgProps = {
    sx: {
      position: 'absolute',
      ...offsets,
      zIndex: 1,
      bg: isTransparent ? 'background' : 'blockBackground',
    },
  }

  const textLine = (children) => {
    return (
      <TypeTextLine
        color={color}
        bg={bg}
        spanProps={spanProps}
        bgProps={bgProps}
        variant={variant}
      >
        {children}
      </TypeTextLine>
    )
  }

  return (
    <div
      sx={{
        position: 'relative',
        color: isTransparent ? 'text' : 'blockText',
        variant: `text.${variant}`,
        pt: paddingTop,

        '&:hover': {
          cursor: isInteractive ? 'pointer' : 'default',
        },
      }}
      {...otherProps}
    >
      {multiline ? (
        <SplitText
          LineWrapper={({ children }) => {
            return (
              <React.Fragment>
                {textLine(children)}
                <br />
              </React.Fragment>
            )
          }}
          LetterWrapper={({ children }) => {
            return <span>{children === '_' ? '-' : children}</span>
          }}
        >
          {children}
        </SplitText>
      ) : (
        textLine(children)
      )}
    </div>
  )
}

const TypeBlockWrapper = (props) => {
  const {
    children,
    on,
    bg: _bg,
    isInteractive,
    bgInteractive,
    ...otherProps
  } = props
  const [interactiveActive, setInteractiveActive] = useState(null)

  const interactiveProps = isInteractive
    ? {
        onPointerEnter: (e) => {
          setInteractiveActive(true)
        },
        onPointerLeave: (e) => {
          setInteractiveActive(false)
        },
      }
    : null

  const isOn = on || (isInteractive && interactiveActive)
  const bg = _bg || (isOn && bgInteractive)

  return isOn ? (
    <TypeBlockColor
      bg={bg}
      isInteractive={isInteractive}
      sx={{ zIndex: 2 }}
      {...interactiveProps}
      {...otherProps}
    >
      {children}
    </TypeBlockColor>
  ) : (
    <TypeBlock
      isInteractive={isInteractive}
      sx={{ zIndex: 1 }}
      {...interactiveProps}
      {...otherProps}
    >
      {children}
    </TypeBlock>
  )
}

export default TypeBlockWrapper
