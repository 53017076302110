/** @jsx jsx */
import React, { useRef, useEffect, useState, useMemo } from 'react'
import { jsx } from 'theme-ui'
import { isFinite, isBoolean, get, isFunction } from 'lodash'
import Vimeo from '@u-wave/react-vimeo'
import { css } from '@emotion/react'
import { motion } from 'framer-motion'
import { useInView } from 'react-cool-inview'
import formatDuration from 'format-duration'

import Image, { FillContainer, InlineContainer, RatioBox } from '../image'

import { calcRatio } from '../../lib/math-helpers'

import { fillArea, crossBrowserStyle, floodBgImage } from '../../styles/css'

import PlayIcon from './play.svg'
import PauseIcon from './pause.svg'
import MuteIcon from './mute.svg'
import VolumeIcon from './volume.svg'

const Icon = (props) => {
  const { icon, ...otherProps } = props

  let iconImg = null

  switch (icon) {
    case 'pause':
      iconImg = PauseIcon
      break
    case 'mute':
      iconImg = MuteIcon
      break
    case 'volume':
      iconImg = VolumeIcon
      break
    default:
      iconImg = PlayIcon
  }

  return (
    <RatioBox
      sx={{
        backgroundImage: `url(${iconImg})`,
      }}
      ratio={1}
      {...otherProps}
    />
  )
}

export const InlineVideo = (props) => {
  const {
    placeholder,
    vimeo,
    imageProps,
    behaviour,
    shouldPlay,
    shouldPause,
    playsOnScroll,
    onEnd,
    ratio,
    contain,
    containedEl,
    ...otherProps
  } = props

  return (
    <InlineContainer size={placeholder} ratio={ratio} {...otherProps}>
      <Video
        vimeo={vimeo}
        placeholder={placeholder}
        imageProps={imageProps}
        behaviour={behaviour}
        shouldPlay={shouldPlay}
        shouldPause={shouldPause}
        playsOnScroll={playsOnScroll}
        contain={contain}
        containedEl={containedEl}
        onEnd={onEnd}
      />
    </InlineContainer>
  )
}

const iconWrapperStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '30px',
  height: '30px',

  '& > div': {
    width: '18px',
  },

  '&:hover': {
    cursor: 'pointer',
  },
}

const Video = (props) => {
  const {
    placeholder,
    contain,
    containedEl,
    vimeo,
    ratio,
    imageProps,
    behaviour: _behaviour,
    shouldPlay,
    shouldPause,
    shouldBeMuted,
    playsOnScroll,
    onEnd,
    ...otherProps
  } = props
  const [behaviour] = useState(_behaviour ? _behaviour : 'default')
  const [vimeoIsReady, setVimeoIsReady] = useState(false)
  const [controlsAreActive, setControlsAreActive] = useState(false)
  const [isPlaying, setIsPlaying] = useState(true)
  const [isMuted, setIsMuted] = useState(false)
  const [time, setTime] = useState(null)
  const [shouldShowPlaceholder] = useState(
    behaviour === 'thumbnail' || behaviour === 'autoplay'
  )
  const timeTrackRef = useRef(null)
  const vimeoPlayerRef = useRef(null)
  const prevTimeRef = useRef(null)
  const { inView, observe } = useInView({
    rootMargin: '50px',
  })

  const tracksScroll = playsOnScroll || behaviour === 'default'
  const paused = controlsAreActive
    ? !isPlaying
    : tracksScroll && inView && !shouldPause
    ? false
    : behaviour === 'thumbnail' && vimeoIsReady && (!tracksScroll || inView)
    ? !shouldPlay
    : behaviour === 'autoplay' && vimeoIsReady
    ? false
    : true
  const muted = controlsAreActive
    ? isMuted
    : isBoolean(shouldBeMuted)
    ? shouldBeMuted
    : true

  const loops = controlsAreActive ? false : true
  const tracksTime = behaviour === 'default' && controlsAreActive
  const hasOnEnd = onEnd && isFunction(onEnd)

  const toStart = () => {
    if (vimeoPlayerRef.current) {
      vimeoPlayerRef.current.setCurrentTime(0)
    }
  }

  useEffect(() => {
    if (controlsAreActive) {
      toStart()
    }
  }, [controlsAreActive])

  useEffect(() => {
    if (behaviour === 'default' && !inView && controlsAreActive) {
      setControlsAreActive(false)
    }
  }, [inView])

  return (
    <FillContainer
      size={placeholder}
      contain={contain}
      containedEl={
        behaviour === 'default' && controlsAreActive ? (
          <React.Fragment>
            {containedEl ? containedEl : null}
            <div
              sx={{
                variant: 'text.body',
                position: 'absolute',
                left: 0,
                bottom: 0,
                p: '10px 10px 10px 10px',
                width: '100%',
                zIndex: 3,
                display: 'flex',
                alignItems: 'stretch',
                justifyContent: 'flex-start',
                color: 'white',
                backgroundColor: 'rgba(0,0,0,0.2)',
              }}
            >
              <div
                sx={iconWrapperStyles}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()

                  setIsPlaying(!isPlaying)
                }}
              >
                <Icon icon={paused ? 'play' : 'pause'} />
              </div>
              {time ? (
                <React.Fragment>
                  <div sx={{ whiteSpace: 'nowrap', p: '9px 15px 0px 10px' }}>
                    {formatDuration(time.seconds * 1000)} /{' '}
                    {formatDuration(time.duration * 1000)}
                  </div>
                  <div
                    sx={{
                      position: 'relative',
                      flex: 1,
                      height: '30px',
                      mr: '15px',

                      '&:hover': {
                        cursor: 'pointer',
                      },
                    }}
                    ref={timeTrackRef}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()

                      const trackRect =
                        timeTrackRef.current.getBoundingClientRect()
                      const xPos = e.pageX
                      const xPerc = (xPos - trackRect.x) / trackRect.width
                      const targetTime = xPerc * time.duration
                      if (vimeoPlayerRef.current) {
                        vimeoPlayerRef.current.setCurrentTime(targetTime)
                      }
                    }}
                  >
                    <div
                      sx={{
                        position: 'absolute',
                        left: 0,
                        top: '50%',
                        width: '2px',
                        height: '10px',
                        mt: '-5px',
                        backgroundColor: 'white',
                      }}
                      style={{
                        left: `${time.percent * 100}%`,
                      }}
                    />
                    <div
                      sx={{
                        position: 'absolute',
                        left: 0,
                        top: '50%',
                        mt: '-1px',
                        width: '100%',
                        height: '2px',
                        backgroundColor: 'white',
                      }}
                    />
                  </div>
                </React.Fragment>
              ) : null}
              <div
                sx={iconWrapperStyles}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()

                  setIsMuted(!isMuted)
                }}
              >
                <Icon icon={muted ? 'mute' : 'volume'} />
              </div>
            </div>
          </React.Fragment>
        ) : containedEl ? (
          containedEl
        ) : null
      }
    >
      {tracksScroll ? (
        <div
          ref={observe}
          sx={{ zIndex: 0, pointerEvents: 'none' }}
          css={css`
            ${fillArea}
          `}
        />
      ) : null}
      {behaviour === 'default' && controlsAreActive ? (
        <div
          sx={{ zIndex: 2 }}
          css={css`
            ${fillArea}
          `}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()

            setIsPlaying(!isPlaying)
          }}
        />
      ) : null}
      {behaviour === 'default' && !controlsAreActive ? (
        <div
          sx={{
            zIndex: 3,
            position: 'absolute',
            left: 0,
            top: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            color: 'white',
            textShadow: '0 0 4px rgba(0, 0, 0, 0.25)',

            '&:hover': {
              cursor: 'pointer',
            },
          }}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()

            if (vimeoIsReady) {
              setControlsAreActive(true)
            }
          }}
        >
          <Icon icon="play" sx={{ width: '62px' }} />
        </div>
      ) : null}
      {shouldShowPlaceholder && placeholder ? (
        <motion.div
          sx={{ zIndex: 2, pointerEvents: 'none' }}
          css={css`
            ${fillArea}
          `}
          animate={shouldShowPlaceholder && paused ? 'in' : 'out'}
          variants={{
            in: {
              opacity: 1,
              transition: {
                duration: 0.3,
              },
            },
            out: {
              opacity: 0,
              transition: {
                duration: 0.2,
              },
            },
          }}
          onAnimationComplete={(a) => {
            if (a === 'in') {
              toStart()
            }
          }}
        >
          <Image image={placeholder} {...imageProps} />
        </motion.div>
      ) : null}
      {vimeo ? (
        <Vimeo
          video={vimeo}
          loop={loops}
          paused={paused}
          controls={false}
          showByline={false}
          showPortrait={false}
          showTitle={false}
          muted={muted}
          volume={muted ? 0 : 1}
          autopause={false}
          onReady={(v) => {
            vimeoPlayerRef.current = v
            setVimeoIsReady(true)
          }}
          onTimeUpdate={
            tracksTime || (hasOnEnd && loops)
              ? (t) => {
                  let cPercent = t.percent
                  if (tracksTime) {
                    setTime(t)
                  }
                  if (hasOnEnd) {
                    const triggerEnd =
                      cPercent >= 0.95 ||
                      (isFinite(prevTimeRef.current) &&
                        prevTimeRef.current >= 0.9 &&
                        cPercent <= 0.025)
                    if (triggerEnd) {
                      onEnd()
                      cPercent = null
                    }
                  }
                  prevTimeRef.current = cPercent
                }
              : null
          }
          onEnd={
            controlsAreActive || (hasOnEnd && !loops)
              ? () => {
                  if (controlsAreActive) {
                    toStart()
                    setIsPlaying(false)
                  }
                  if (hasOnEnd) {
                    onEnd()
                  }
                }
              : null
          }
          sx={{
            zIndex: 1,
          }}
          css={css`
            ${fillArea};

            iframe {
              ${fillArea};
              pointer-events: none;
            }
          `}
        />
      ) : null}
    </FillContainer>
  )
}

export default Video
