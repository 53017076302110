/** @jsx jsx */
import React, { useEffect } from 'react'
import { jsx } from 'theme-ui'
import { get } from 'lodash'
import { useAtom, useSetAtom } from 'jotai'

import VideoEl from '../../video'

import { formatImage } from '../../../lib/helpers'

import { isMutedAtom, slideHasSoundAtom } from '../index'

const Video = (props) => {
  const {
    fieldGroupName,
    vimeoId,
    placeholder: _placeholder,
    hasSound,
    isActive,
    isInview,
    containedEl,
  } = props

  const [isMuted] = useAtom(isMutedAtom)
  const placeholder = formatImage(_placeholder)

  return vimeoId ? (
    <VideoEl
      placeholder={placeholder}
      vimeo={vimeoId}
      behaviour="thumbnail"
      shouldPlay={isInview}
      shouldBeMuted={!hasSound ? false : isMuted}
      contain={true}
      containedEl={containedEl}
      imageProps={{
        gatsbyImageProps: {
          loading: 'eager',
        },
      }}
    />
  ) : null
}

export default Video
