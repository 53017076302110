/** @jsx jsx */
import React, { useState, useMemo, useEffect } from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'
import { get, each, isFinite, range, map, includes } from 'lodash'
import { motion, useMotionValue, animate, useTransform } from 'framer-motion'
import { useAtom, useSetAtom, useAtomValue } from 'jotai'

import { FadingColourOverlay } from '../colour-cycle'

import useScreenSize from '../../lib/hooks/use-screen-size'
import { FullsizeImage } from '../../lib/fragments'

import { settings as animationSettings } from '../../lib/animation'

import { fillScreen, hardwareAccelerate } from '../../styles/css'

import Arrow from './arrow'
import * as Slides from './slides'

import {
  activeSlideAtom,
  projectInfoActiveAtom,
  taglineActiveAtom,
  targetSlideAtom,
} from './index'
import { useThemeUISetValue } from '../../styles/utils'
import useCarousel from '../../lib/hooks/use-carousel'

const Gallery = (props) => {
  const {
    activeSlide,
    targetSlide,
    inviewSlides,
    images,
    imageCount,
    screenSize,
    headerHeight,
    galleryBottom,
    ...otherProps
  } = props

  const setTargetSlide = useSetAtom(targetSlideAtom)
  const setActiveSlide = useSetAtom(activeSlideAtom)
  const projectInfoActive = useAtomValue(projectInfoActiveAtom)
  const taglineActive = useAtomValue(taglineActiveAtom)

  const {
    refToObserve,
    width,
    trayWidth,
    hasDimensions,
    visibleMap,
    visibleCount,
    xOffset,
    dragEvents,
    shiftIndex,
  } = useCarousel({
    count: imageCount,
    activeIndex: activeSlide,
    targetIndex: targetSlide,
    setTargetIndex: setTargetSlide,
    setActiveIndex: setActiveSlide,
    listenToArrowKeys: !projectInfoActive,
  })

  const hasScreenSize =
    screenSize && isFinite(screenSize.width) && isFinite(screenSize.height)

  return images && imageCount > 0 ? (
    <div
      sx={{
        overflow: 'hidden',
      }}
      css={css`
        ${fillScreen}
      `}
      style={{
        width: hasScreenSize ? screenSize.width : null,
        height: hasScreenSize ? screenSize.height : null,
      }}
      ref={refToObserve}
      {...otherProps}
    >
      <Arrow
        sx={{
          left: 0,
          top: 0,
          zIndex: 3,
        }}
        direction="left"
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()

          shiftIndex(-1)
        }}
      />
      <Arrow
        sx={{
          right: 0,
          top: 0,
          zIndex: 3,
        }}
        direction="right"
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()

          shiftIndex()
        }}
      />
      <motion.div
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: `${visibleCount * 100}%`,
          height: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          zIndex: 1,
          willChange: 'transform',
        }}
        style={{
          x: xOffset,
          width: hasDimensions ? trayWidth : null,
        }}
        css={css`
          ${hardwareAccelerate}
        `}
        {...dragEvents}
      >
        {visibleMap.map((_index, _i) => {
          const _isImage = isFinite(_index)
          const _image = images[_index]
          const _isActive = _index === activeSlide
          const _isInview = includes(inviewSlides, _index)
          const SlideComponent = _image
            ? get(Slides, Slides.componentMap[_image.__typename])
            : null

          return (
            <div
              key={_isImage ? `${get(_image, 'projectId')}_${_index}` : _i}
              sx={{
                position: 'relative',
                height: '100%',
              }}
              style={{
                width: hasDimensions ? width : `${100 / visibleCount}%`,
              }}
            >
              {_isImage && SlideComponent ? (
                <div
                  sx={{
                    position: 'absolute',
                    left: [2, null, 3, 120],
                    right: [2, null, 3, 120],
                    top: headerHeight,
                    bottom: galleryBottom,
                    overflow: 'hidden',
                  }}
                >
                  <SlideComponent
                    {..._image}
                    isActive={_isActive}
                    isInview={_isInview}
                    containedEl={
                      <FadingColourOverlay
                        hidden={!taglineActive}
                        sx={{ zIndex: 5 }}
                      />
                    }
                  />
                </div>
              ) : null}
            </div>
          )
        })}
      </motion.div>
    </div>
  ) : null
}

export default Gallery
