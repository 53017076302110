import { get } from 'lodash'

import colors, { colorData } from './colors'
import { typeData, typeVariants } from './type'
import { breakpointData } from './breakpoints'

import {
  mathOnCss,
  mixColor,
  valueFromTheme,
  fnOnThemeValue,
  mathOnSingleThemeValue,
  mathOnTypeVariant,
} from '../utils'

const lightColorMode = {
  text: colorData.black,
  textSecondary: mixColor(colorData.white, colorData.black, 0.64),
  background: colorData.white,
  blockText: colorData.white,
  blockBackground: colorData.black,
}
const darkColorMode = {
  text: colorData.white,
  textSecondary: mixColor(colorData.black, colorData.white, 0.64),
  background: colorData.black,
  blockText: colorData.black,
  blockBackground: colorData.white,
}

const mathOnLineHeight = (v, cb) => {
  const _theme = {
    ...typeData,
    text: {
      ...typeVariants,
    },
  }
  return mathOnTypeVariant(v, _theme, 'lineHeight', cb)
}

export default {
  // BREAKPOINTS
  breakpoints: [...breakpointData],

  // COLORS
  colors: {
    ...darkColorMode,
    modes: {
      light: {
        ...lightColorMode,
      },
      dark: {
        ...darkColorMode,
      },
    },
    ...colorData,
  },

  // SPACES
  space: [0, 10, 20, 40, 80],

  // TYPOGRAPHY
  ...typeData,

  // VARIANTS
  text: {
    ...typeVariants,
  },

  textBlock: {
    body: {
      variant: 'text.body',

      'h1, h2, ul, ol, p': {
        pb: mathOnLineHeight('body', (v) => v),

        '&:last-child': {
          pb: 0,
        },
      },
    },
    body2: {
      variant: 'text.body2',

      h1: {
        variant: 'text.body2Bold',
      },

      'h2, h3, h4, h5, h6': {
        variant: 'text.body2',
      },

      a: {
        transition: 'opacity 0.4s ease-in-out',

        '&:hover': {
          opacity: 0.6,
          cursor: 'pointer',
        },
      },

      'h1, h2, h3, ul, ol, p': {
        pb: mathOnLineHeight('body2', (v) => v),

        '&:last-child': {
          pb: 0,
        },
      },

      'h1 + h2': {
        mt: mathOnLineHeight('body2', (v) => v * -1),
      },

      'ul, ol': {
        li: {
          pl: mathOnLineHeight('body2', (v) => v),
          position: 'relative',

          '&:before': {
            content: '" "',
            position: 'absolute',
            left: 0,
            top: mathOnLineHeight('body2', (v) => v * 0.55),
            width: '8px',
            height: '8px',
            backgroundColor: 'text',
            borderRadius: '4px',
            mt: '-4px',
          },
        },
      },
    },
    body3: {
      variant: 'text.body3',

      h3: {
        variant: 'text.body3Bold',
      },

      'h1, h2, ul, ol, p': {
        pb: mathOnLineHeight('body3', (v) => v),

        '&:last-child': {
          pb: 0,
        },
      },
    },
  },

  // SET VALUES
  setValues: {
    outerPadding: {
      property: 'space',
      value: [2, null, 3],
    },
    headerHeight: ['60px', null, '100px'],
    arrowWidth: ['40px', null, '60px'],
  },

  // STYLES
  styles: {
    root: {
      variant: 'text.body',
    },
  },

  useColorSchemeMediaQuery: false,
  useCustomProperties: false,
}
