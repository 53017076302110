/** @jsx jsx */
import React, { useEffect, useState, useMemo } from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { get, each, findIndex, find, isFinite } from 'lodash'
import { atom, useAtom, useSetAtom } from 'jotai'

import { RatioBox } from '../image'

import useScreenSize from '../../lib/hooks/use-screen-size'
import {
  FullsizeImage,
  PlaceholderImage,
  PortraitImage,
} from '../../lib/fragments'
import { updateURL } from '../../lib/helpers'

import { fillScreen } from '../../styles/css'
import { useThemeUISetValue } from '../../styles/utils'

import Footer from './footer'
import Gallery from './gallery'
import Tagline from './tagline'

import { useAllWorkPreviewFilter, useIsPreviewUrl } from './use-all-work'

const calcImages = (projects = []) => {
  const images = []

  each(projects, (_n, _i) => {
    const _images = get(_n, 'acf.images')
    if (_images && _images.length > 0) {
      images.push(
        ..._images.map((_image, _j) => {
          return {
            ..._image,
            projectId: _n.databaseId,
          }
        })
      )
    }
  })

  return {
    images,
    imageCount: images.length,
  }
}

const slideForProjectId = (projectId, imageData) => {
  return findIndex(imageData.images, { projectId })
}

const calcInitialSlideIndex = (projectId, initSlideIndex, imageData) => {
  const startSlide = slideForProjectId(projectId, imageData)
  if (startSlide < 0) {
    return initSlideIndex
  } else {
    return startSlide
  }
}

export const parseProjectData = (projects) => {
  return [calcImages(projects), projects]
}

export const projectForSlideIndex = (
  active,
  inview,
  imageData,
  projectData
) => {
  const _slideIndex = !inview || inview.length <= 1 ? active : inview[1]
  const _slide = get(imageData, `images.${_slideIndex}`)
  const _projectId = _slide ? _slide.projectId : null
  const activeProjectIndex = findIndex(projectData, { databaseId: _projectId })

  return isFinite(activeProjectIndex) &&
    activeProjectIndex >= 0 &&
    activeProjectIndex < projectData.length
    ? projectData[activeProjectIndex]
    : null
}

export const activeSlideAtom = atom(0)
export const targetSlideAtom = atom(0)
export const inviewSlidesAtom = atom((get) => {
  const target = get(targetSlideAtom)
  const active = get(activeSlideAtom)
  const inview = [active]
  if (isFinite(target) && target !== active) {
    inview.push(target)
  }
  return inview
})
export const isMutedAtom = atom(true)
export const slideHasSoundAtom = atom(false)
export const projectInfoActiveAtom = atom(false)
export const taglineActiveAtom = atom(false)

const WorkGallery = (props) => {
  const { startProject, location, ...otherProps } = props
  const workData = useAllWorkPreviewFilter()

  const [hasSetProject, setHasSetProject] = useState(false)
  const [imageData, projectData] = useMemo(() => {
    return parseProjectData(workData)
  }, [workData])

  const [_activeSlide, setActiveSlide] = useAtom(activeSlideAtom)
  const [targetSlide, setTargetSlide] = useAtom(targetSlideAtom)
  const [inviewSlides] = useAtom(inviewSlidesAtom)
  const [taglineActive, setTaglineActive] = useAtom(taglineActiveAtom)
  const setSlideHasSound = useSetAtom(slideHasSoundAtom)

  const activeSlide = !hasSetProject
    ? calcInitialSlideIndex(startProject, _activeSlide, imageData)
    : _activeSlide

  const project = useMemo(() => {
    return projectForSlideIndex(
      activeSlide,
      inviewSlides,
      imageData,
      projectData
    )
  }, [activeSlide, inviewSlides, imageData, projectData])

  const screenSize = useScreenSize()

  const headerHeight = useThemeUISetValue('headerHeight')
  const galleryBottom = ['120px', ...headerHeight.slice(1)]

  useEffect(() => {
    if (hasSetProject) {
      const projectPath = get(project, 'uri')
      const locationPath = get(location, 'pathname')

      if (projectPath !== locationPath) {
        updateURL(projectPath, { replace: true })
      }
    }
  }, [activeSlide, project, location, hasSetProject])
  useEffect(() => {
    const startSlide = slideForProjectId(startProject, imageData)
    if (startSlide !== _activeSlide) {
      setTargetSlide(startSlide)
      setActiveSlide(startSlide)
    }
    setHasSetProject(true)

    return () => {
      setTargetSlide(null)
      setSlideHasSound(false)
    }
  }, [])
  useEffect(() => {
    const activeImage = imageData?.images?.[activeSlide]
    if (activeImage) {
      const isVideoAndHasSound =
        activeImage?.__typename === 'WpWork_Acf_Images_Video' &&
        !!activeImage?.hasSound
      setSlideHasSound(isVideoAndHasSound)
    }
  }, [activeSlide, imageData])

  return (
    <div
      css={css`
        ${fillScreen}
      `}
      style={{
        width: screenSize.width,
        height: screenSize.height,
      }}
      {...otherProps}
    >
      <RatioBox
        sx={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          width: ['25%', '16%'],
          minWidth: '50px',
          transform: 'translate3d(-50%,-50%,0)',
          zIndex: 6,
        }}
        ratio={1}
        onMouseOver={(e) => {
          if (!taglineActive) {
            setTaglineActive(true)
          }
        }}
        onMouseLeave={(e) => {
          if (taglineActive) {
            setTaglineActive(false)
          }
        }}
      />
      <Gallery
        activeSlide={activeSlide}
        targetSlide={targetSlide}
        inviewSlides={inviewSlides}
        headerHeight={headerHeight}
        galleryBottom={galleryBottom}
        {...imageData}
        screenSize={screenSize}
        sx={{ zIndex: 1 }}
      />
      <Footer project={project} sx={{ zIndex: 2 }} />
      {taglineActive ? (
        <Tagline
          project={project}
          headerHeight={headerHeight}
          galleryBottom={galleryBottom}
        />
      ) : null}
    </div>
  )
}

export default WorkGallery
