/** @jsx jsx */
import React from 'react'
import { jsx, css as csx, Box } from 'theme-ui'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { get, isArray } from 'lodash'

import { roundTo } from '../lib/math-helpers'

import { fillArea, clearFix } from './css'
import {
  valueFromTheme,
  percForProps,
  mathOnThemeValue,
  mathOnTypeVariant,
} from './utils'

export const TextBlock = styled.div`
  ${({ variant }) =>
    csx({
      variant: `textBlock.${variant ? variant : 'body2'}`,
    })}
`

const ratioToPercent = (ratio = 1) => {
  return `${roundTo(ratio * 100, 100)}%`
}

export const BGImage = styled.div`
  ${(props) =>
    csx({
      position: 'relative',
      width: '100%',
      clear: 'both',

      '&:before': {
        content: '" "',
        display: 'block',
        width: '100%',
        pb: props.ratio ? ratioToPercent(props.ratio) : '100%',
        backgroundImage: props.image ? `url(${props.image})` : null,
        backgroundSize: '100% auto',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      },
    })}
`

export const Col = styled.div`
  position: relative;
  ${(props) =>
    csx({
      width: percForProps(props),
    })};
`

export const ColEmpty = styled(Col)`
  ${csx({
    display: ['none', 'block'],
  })};

  &:before {
    content: ' ';
    display: block;
    width: 100%;
    height: 1px;
  }
`

export const Cols = styled.div(({ space, theme }) => {
  const spaceObj = get(theme, 'space')
  const _space = spaceObj && isFinite(space) && spaceObj[space] ? space : 2

  return css`
    display: flex;
    flex-wrap: wrap;
    ${clearFix};
    margin-right: ${spaceObj[_space] * -1}px;
    ${Col},
    ${ColEmpty} {
      padding-right: ${spaceObj[_space]}px;
    }
  `
})

// ———————————————

export const Cont = styled.div`
  margin: 0px auto;
  ${csx({
    width: ['100%', null, null, null, null, `${(8 / 10) * 100}%`],
  })}
`

export const FillArea = styled.div`
  ${fillArea};
`

export const FlexCol = styled.div(({ theme, ...otherProps }) => {
  const width = percForProps(otherProps)

  return csx({
    position: 'relative',
    width,
  })
})

export const FlexColEmpty = styled(FlexCol)`
  display: block;
`

export const FlexCols = styled.div(({ space, theme }) => {
  const padding = valueFromTheme(
    'padding',
    isArray(space) || isFinite(space) ? space : 4,
    theme,
    { parse: true }
  )
  const p = mathOnThemeValue(padding, (_v) => _v)
  const pN = mathOnThemeValue(padding, (_v) => _v * -1)
  const hP = mathOnThemeValue(p, (_v) => _v * 0.5)
  const hPN = mathOnThemeValue(p, (_v) => _v * -0.5)

  return csx({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    ml: hPN,
    mr: hPN,
    mb: pN,

    [`${FlexCol}, ${FlexColEmpty}`]: {
      pl: hP,
      pr: hP,
      pb: p,
    },
  })
})

export const TextSpacer = styled.div(({ textVariant, theme }) => {
  const lineHeight = mathOnTypeVariant(
    textVariant,
    theme,
    'lineHeight',
    (_v) => _v
  )

  return lineHeight
    ? csx({
        pb: lineHeight,
      })
    : null
})
