/** @jsx jsx */
import React, { useMemo, useState } from 'react'
import { jsx } from 'theme-ui'
import { mathOnThemeValue, useThemeUISetValue } from '../../styles/utils'

import Icon from '../icon'

const Arrow = (props) => {
  const { direction, ...otherProps } = props

  const arrowWidth = useThemeUISetValue('arrowWidth')
  const outerPadding = useThemeUISetValue('outerPadding')
  const [mt, mb, mx] = useMemo(() => {
    const mh = mathOnThemeValue(arrowWidth, (v) => v * -0.5)
    return [
      ['auto', null, ...mh.slice(2)],
      [...mh.slice(0, 2), 'auto'],
      mathOnThemeValue(arrowWidth, (v) => v * -0.25),
    ]
  }, arrowWidth)

  const inverse = direction === 'right'

  return (
    <div
      sx={{
        position: 'absolute',
        width: ['40px', null, '80px'],
        height: '100%',

        '&:hover': {
          cursor: 'pointer',
        },
      }}
      {...otherProps}
    >
      <Icon
        icon="arrow"
        sx={{
          position: 'absolute',
          top: [null, null, '50%'],
          bottom: ['40px', null, 'auto'],
          mt,
          mb,
          width: arrowWidth,
          [inverse ? 'right' : 'left']: outerPadding,
          [inverse ? 'mr' : 'ml']: mx,
          opacity: 0.5,
        }}
        direction={direction ? direction : 'left'}
      />
    </div>
  )
}

export default Arrow
