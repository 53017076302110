import Flick from './flick'
import FlickSplit from './flick-split'
import Image from './image'
import Video from './video'

const componentMap = {
  WpWork_Acf_Images_Flick: 'Flick',
  WpWork_Acf_Images_FlickSplit: 'FlickSplit',
  WpWork_Acf_Images_Image: 'Image',
  WpWork_Acf_Images_Video: 'Video',
}

export { Flick, FlickSplit, Image, Video, componentMap }
