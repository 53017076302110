/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import { get } from 'lodash'
import WebFont from 'webfontloader'
import { useSetAtom } from 'jotai'

import Header from '../components/header'
import Menu, { menuActiveAtom } from '../components/menu'
import PageTransition from '../components/page-transition'
import WorkInfoPanel from '../components/work-info-panel'

import Global from '../styles/global'

const Layout = (props) => {
  const { children, path, location, ...otherProps } = props

  const setMenuActive = useSetAtom(menuActiveAtom)

  useEffect(() => {
    setMenuActive(false)
  }, [location])

  useEffect(() => {
    if (WebFont) {
      WebFont.load({
        custom: {
          families: [
            'Swiss721BT-Roman',
            'Swiss721BT-Bold',
            'Swiss721BT-Light',
            'Canela:n3,i2',
          ],
        },
      })
    }
  }, [WebFont])

  return (
    <React.Fragment>
      <Global />
      <Header />
      <Menu />
      <WorkInfoPanel />
      <PageTransition path={path} {...otherProps}>
        {children}
      </PageTransition>
    </React.Fragment>
  )
}

export default Layout

/*

const title = 'LUKECHARLES'
  const description =
    'We create identities and brands that have transformational success for our clients'

    
<Helmet>
<title>{title}</title>
<meta property="og:title" content={title} />
<meta name="description" content={description} />
<meta property="og:description" content={description} />
<meta property="og:image" content={image} />
<meta name="twitter:card" content="summary_large_image" />
</Helmet>


*/
