/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui'
import React from 'react'

import { BGImage } from '../../styles/els'
import { getColorMode } from '../../styles/utils'

import ArrowBlack from './arrow-black.svg'
import ArrowWhite from './arrow-white.svg'
import GridBlack from './grid-black.svg'
import GridWhite from './grid-white.svg'
import GridOutlineBlack from './grid_outline-black.svg'
import GridOutlineWhite from './grid_outline-white.svg'
import SoundOffBlack from './soundoff-black.svg'
import SoundOffWhite from './soundoff-white.svg'
import SoundOnBlack from './soundon-black.svg'
import SoundOnWhite from './soundon-white.svg'

const rotationFromDirection = (d) => {
  switch (d) {
    case 'left':
      return -90
    case 'right':
      return 90
    case 'down':
      return 180
    default:
      return 0
  }
}

const imageFromIcon = (icon, color) => {
  const isLight = color === 'light'

  switch (icon) {
    case 'arrow':
      return isLight ? ArrowWhite : ArrowBlack
    case 'grid':
      return isLight ? GridWhite : GridBlack
    case 'gridOutline':
      return isLight ? GridOutlineWhite : GridOutlineBlack
    case 'soundOn':
      return isLight ? SoundOnWhite : SoundOnBlack
    case 'soundOff':
      return isLight ? SoundOffWhite : SoundOffBlack
    default:
      return null
  }
}

export default (props) => {
  const { icon, direction, color, ...otherProps } = props

  const [_colorMode] = useColorMode()

  const colorMode = getColorMode(_colorMode)
  const image = imageFromIcon(
    icon,
    (!color && colorMode === 'dark') || color === 'light' ? 'light' : 'dark'
  )

  return image ? (
    <BGImage
      sx={{
        width: '72px',
        transform: direction
          ? `rotate(${rotationFromDirection(direction)}deg)`
          : null,
      }}
      ratio={1}
      image={image}
      {...otherProps}
    />
  ) : null
}
