import { getImage } from 'gatsby-plugin-image'
import { get } from 'lodash'
import htmr from 'htmr'
import parse from 'html-react-parser'
import { Link } from 'gatsby'

export const formatImage = (image) => {
  if (!image) {
    return null
  }

  const _image =
    get(image, 'localFile.childImageSharp.gatsbyImageData') ||
    get(image, 'childImageSharp.gatsbyImageData') ||
    get(image, 'gatsbyImageData') ||
    image

  const gatsbyImage = getImage(_image)

  if (gatsbyImage) {
    return gatsbyImage
  }

  const publicURL = get(image, 'localFile.publicURL') || get(image, 'publicURL')
  const size = get(image, 'mediaDetails')

  if (publicURL && size) {
    return {
      src: publicURL,
      width: size.width,
      height: size.height,
    }
  }

  return null
}

export const formatVimeo = (vimeo) => {
  if (!vimeo) {
    return null
  }

  return {
    placeholder: formatImage(get(vimeo, 'placeholder')),
    vimeo: get(vimeo, 'vimeoId'),
  }
}

export const formatLink = (path) => {
  return addSlugToLink(path, '/')
}

export const flickImageResolver = (_i) => {
  return formatImage(get(_i, 'image'))
}

const addSlugToLink = (slug = '', path = '') => {
  if (slug.charAt(0) === '/') {
    slug = slug.slice(1)
  }

  path += slug

  if (path.slice(-1) !== '/') {
  }

  return path
}

export const renderHTML = (str) => {
  return str
    ? htmr(str.replace(/“/g, `<span class="quoteIndent">“</span>`))
    : null
}

export const updateURL = (url, { replace }) => {
  if (typeof window !== `undefined` && url) {
    const state = null
    const title = ''
    if (replace) {
      window.history.replaceState(state, title, url)
    } else {
      window.history.pushState(state, title, url)
    }
  }
  return
}
