/** @jsx jsx */
import React from 'react'
import { jsx, csx, useThemeUI, useColorMode } from 'theme-ui'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'

import { getColorFromColorMode } from '../../styles/utils'

const Bar = motion(
  styled.div(
    {
      width: '100%',

      '&:last-child': {
        marginBottom: '0px',
      },
    },
    (props) => ({
      height: props.thin ? '1px' : '3px',
      marginBottom: props.thin ? '7px' : '5px',
    })
  )
)

const barTransition = {
  type: 'tween',
  duration: 0.5,
  ease: 'easeInOut',
}

const Hamburger = (props) => {
  const { color, open, thin, ...otherProps } = props
  const { theme } = useThemeUI()
  const [colorMode] = useColorMode()

  const barState = open ? 'open' : 'closed'

  const bg = getColorFromColorMode(
    color || !colorMode ? 'background' : 'text',
    color ? color : colorMode,
    theme
  )

  return (
    <div {...otherProps}>
      <Bar
        sx={{
          background: bg,
        }}
        thin={thin}
        animate={barState}
        transition={barTransition}
        variants={{
          closed: {
            rotate: 0,
            translateY: 0,
          },
          open: {
            rotate: 45,
            translateY: 8,
          },
        }}
      />
      <Bar
        sx={{ background: bg }}
        thin={thin}
        animate={barState}
        transition={barTransition}
        variants={{
          closed: {
            scaleX: 1,
          },
          open: {
            scaleX: 0,
          },
        }}
      />
      <Bar
        sx={{
          background: bg,
        }}
        thin={thin}
        animate={barState}
        transition={barTransition}
        variants={{
          closed: {
            rotate: 0,
            translateY: 0,
          },
          open: {
            rotate: -45,
            translateY: -8,
          },
        }}
      />
    </div>
  )
}

export default Hamburger
