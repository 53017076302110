exports.components = {
  "component---src-pages-index-old-js": () => import("./../../../src/pages/index-old.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-templates-single-work-js": () => import("./../../../src/templates/single-work.js" /* webpackChunkName: "component---src-templates-single-work-js" */),
  "component---src-templates-template-about-js": () => import("./../../../src/templates/template-about.js" /* webpackChunkName: "component---src-templates-template-about-js" */),
  "component---src-templates-template-contact-js": () => import("./../../../src/templates/template-contact.js" /* webpackChunkName: "component---src-templates-template-contact-js" */),
  "component---src-templates-template-homepage-js": () => import("./../../../src/templates/template-homepage.js" /* webpackChunkName: "component---src-templates-template-homepage-js" */),
  "component---src-templates-template-text-js": () => import("./../../../src/templates/template-text.js" /* webpackChunkName: "component---src-templates-template-text-js" */),
  "component---src-templates-template-work-js": () => import("./../../../src/templates/template-work.js" /* webpackChunkName: "component---src-templates-template-work-js" */)
}

