import { buildColorObject } from '../utils'

const colors = {
  black: [0, 0, 0],
  white: [255, 255, 255],
}

export default colors

export const colorData = buildColorObject(colors)
