/** @jsx jsx */
import React, { useMemo, useRef, useEffect } from 'react'
import { jsx, useThemeUI } from 'theme-ui'
import { isFunction, isFinite, fill } from 'lodash'

import useArrayOfSize from '../../lib/hooks/use-array-of-size'

const Breadcrumbs = (props) => {
  const { styles, count, onBreadcrumbClick, activeIndex, ...otherProps } = props
  const arr = useArrayOfSize(count)

  return (
    <div sx={{ display: 'flex' }} {...otherProps}>
      {arr && arr.length > 0
        ? arr.map((_v, _i) => {
            const _style = styles && styles[_i] ? styles[_i] : null
            const _background =
              _style && _style.background
                ? _style.background
                : isFinite(activeIndex) && activeIndex === _i
                ? 'text'
                : 'background'
            const _border = _style && _style.border ? _style.border : 'text'

            return (
              <div
                key={_i}
                sx={{
                  width: '14px',
                  height: '14px',
                  position: 'relative',

                  ':before': {
                    content: '" "',
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    ml: '-5px',
                    mt: '-5px',
                    width: '8px',
                    height: '8px',
                    borderRadius: '5px',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: _border,
                    backgroundColor: _background,
                  },

                  ':hover': {
                    cursor: 'pointer',
                  },
                }}
                onClick={
                  isFunction(onBreadcrumbClick)
                    ? (e) => {
                        e.preventDefault()
                        e.stopPropagation()

                        onBreadcrumbClick(_i)
                      }
                    : null
                }
              />
            )
          })
        : null}
    </div>
  )
}

export default Breadcrumbs
