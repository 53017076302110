import { useMemo } from 'react'
import { css } from '@emotion/react'
import { rgb, rgba, getValueAndUnit, mix, math, stripUnit } from 'polished'
import {
  each,
  get,
  has,
  indexOf,
  keys,
  reduce,
  sortBy,
  isFinite,
  isArray,
  isFunction,
  isUndefined,
  isNil,
  isNull,
  isObject,
} from 'lodash'
import { useThemeUI } from 'theme-ui'
import useScreenSize from '../lib/hooks/use-screen-size'

export const fontFaceSrc = (files = {}, name = null) => css`
  ${files.eot
    ? css`
        src: url('${files.eot}');
      `
    : null}
  ${files && keys(files).length > 0
    ? css`
        src: ${orderFontFaceKeys(keys(files))
          .map((_key, _i) => {
            const _format = fontFaceFormat(_key)

            return `url('${files[_key]}${
              _key === 'eot'
                ? `#iefix`
                : _key === 'svg'
                ? `#${name.replace(/["']/g, '')}`
                : ``
            }')${_format ? ` format('${_format}')` : ``}`
          })
          .join(',')};
      `
    : null}
`

const fontFaceFormat = (type) => {
  switch (type) {
    case 'eot':
      return 'embedded-opentype'
    case 'ttf':
      return 'truetype'
    case 'svg':
    case 'woff2':
    case 'woff':
      return type
    default:
      return null
  }
}

const orderFontFaceKeys = (keys) => {
  const order = ['eot', 'woff2', 'woff', 'ttf', 'svg']
  return sortBy(keys, (_k) => {
    return indexOf(order, _k)
  })
}

export const buildColorObject = (c) => {
  let colors = {}

  each(keys(c), (_k, _i) => {
    colors[_k] = parseSetColor(c[_k])
  })

  return colors
}

export const parseSetColor = (c) => {
  if (!c || !isArray(c) || c.length <= 2) {
    return null
  }
  return rgba(c[0], c[1], c[2], isFinite(c[3]) ? c[3] : 1)
}

export const mixColor = (a, b, p = 0.5) => {
  return mix(p, a, b)
}

export const buildTypeVariants = (variants, styles, sizes, details = {}) => {
  let _v = {}

  each(keys(variants), (_k, _i) => {
    _v[_k] = buildTypeVariant(
      variants[_k][0],
      variants[_k][1],
      variants[_k][2],
      styles,
      sizes,
      details
    )
  })

  return _v
}

export const buildTypeVariant = (
  variantStyle,
  variantSize,
  variantDetail,
  styles,
  sizes,
  details
) => {
  return {
    ...fillTypeVariant('style', variantStyle, styles),
    ...fillTypeVariant('size', variantSize, sizes),
    ...fillTypeVariant('detail', variantDetail, details),
  }
}

const typeVariantExists = (variantKey, typeData) => {
  return !!typeData[variantKey]
}

const propertiesToBuildTypeVariant = (property) => {
  switch (property) {
    case 'style':
      return ['fontFamily', 'fontWeight', ['fontStyle', 'style']]
    case 'size':
      return ['fontSize', 'lineHeight', 'letterSpacing']
    default:
      return null
  }
}

const fillTypeVariant = (property, variantKey, typeData) => {
  let v = null
  if (isArray(variantKey) && variantKey.length > 0) {
    v = variantKey.map((_k, _i) => {
      return typeVariantExists(_k, typeData) ? _k : null
    })
  } else {
    v = typeVariantExists(variantKey, typeData) ? variantKey : null
  }

  if (v) {
    const properties = propertiesToBuildTypeVariant(property)
    return properties
      ? reduce(
          properties,
          (_o, _p, _i) => {
            const _propetiesToAdd =
              isArray(_p) && _p.length === 2
                ? { [_p[0]]: get(typeData, `${v}.${_p[1]}`) }
                : { [_p]: v }
            return {
              ..._o,
              ..._propetiesToAdd,
            }
          },
          {}
        )
      : !isArray(v)
      ? { ...typeData[v] }
      : {}
  } else {
    return {}
  }
}

export const buildTypeObject = (styles, sizes) => {
  let fonts = {}
  let fontWeights = {}
  let fontSizes = {}
  let lineHeights = {}
  let letterSpacings = {}

  each(keys(styles), (_k, _i) => {
    fonts[_k] = styles[_k].font
    fontWeights[_k] = styles[_k].weight
  })

  each(keys(sizes), (_k, _i) => {
    fontSizes[_k] = sizes[_k][0]
    lineHeights[_k] = sizes[_k][1]
    if (!isNil(sizes[_k][2])) {
      letterSpacings[_k] = sizes[_k][2]
    }
  })

  return {
    fonts,
    fontWeights,
    fontSizes,
    lineHeights,
    letterSpacings,
  }
}

export const buildBreakpoints = (breakpoints = null) => {
  let arr = []
  each(keys(breakpoints), (_k, _i) => {
    arr.push(breakpoints[_k])
  })

  return sortBy(arr, (_v) => _v).map((_v) => {
    return `${_v}px`
  })
}

export const themeTextBlockPPadding = (lineHeight, cb) => {
  return {
    pb: isFunction(cb) ? mathOnCss(lineHeight, cb) : lineHeight,

    '&:last-child': {
      pb: 0,
    },
  }
}

const mapPropertyToThemeUIKey = (property) => {
  switch (property) {
    case 'padding':
    case 'margin':
      return 'space'
    case 'zIndex':
      return 'zIndices'
    default:
      return property
  }
}

export const valueFromTheme = (property, value, theme, _opts = {}) => {
  const opts = {
    parse: false,
    ..._opts,
  }
  const { parse } = opts

  const key = parse ? mapPropertyToThemeUIKey(property) : property
  const themeValue = fnOnThemeValue(value, (_v) => {
    const __values = get(theme, key)
    if (!has(__values, _v)) {
      return _v
    } else {
      const __v = get(__values, _v)
      return isNil(__v) ? null : __v
    }
  })
  return !isUndefined(themeValue) ? themeValue : value
}

export const mathOnValueFromTheme = (
  property,
  value,
  theme,
  cb,
  _opts = {}
) => {
  const opts = {
    parse: true,
    ..._opts,
  }
  const v = valueFromTheme(property, value, theme, opts)
  return mathOnThemeValue(v, cb, opts)
}

export const mathOnThemeValue = (v, cb, _opts = {}) => {
  const { fallbackUnit } = {
    fallbackUnit: null,
    ..._opts,
  }
  return fnOnThemeValue(v, (_v) => {
    return mathOnSingleThemeValue(_v, cb, fallbackUnit)
  })
}

export const mathOnSingleThemeValue = (v, cb, fallbackUnit = null) => {
  const [input, _unit] = getValueAndUnit(v)

  if (isNil(input) || isNaN(input)) {
    return null
  }

  const newValue = fnOrValue(input, cb)
  const unit = _unit || fallbackUnit

  return isNil(newValue) || isNaN(newValue)
    ? null
    : !unit
    ? newValue
    : `${newValue}${unit}`
}

export const mathOnTypeVariant = (variantName, theme, property, cb) => {
  return fnOnThemeValue(get(theme, `text.${variantName}.${property}`), (_v) => {
    const v = valueFromTheme(`${property}s`, _v, theme)
    return mathOnSingleThemeValue(v, cb)
  })
}

const fnOrValue = (v, cb) => {
  return isFunction(cb) ? cb(v) : v
}

export const fnOnThemeValue = (v, cb) => {
  return isArray(v)
    ? v.map((_v, _i) => {
        return fnOrValue(_v, cb)
      })
    : fnOrValue(v, cb)
}

export const getOtherColorMode = (_mode) => {
  const mode = getColorMode(_mode)
  return mode === 'dark' ? 'light' : 'dark'
}

export const getColorFromColorMode = (key, mode = 'light', theme) => {
  return get(theme, `colors.modes.${getColorMode(mode)}.${key}`)
}

export const getColorMode = (mode) => {
  return mode === 'default' || !mode ? 'dark' : mode
}

export const crossBrowserStyle = (key = '', value = '') => {
  let objStyles = {}
  const capKey =
    key && key.length > 0
      ? `${key.charAt(0).toUpperCase()}${key.slice(1)}`
      : key

  switch (key) {
    case 'transform':
      objStyles = {
        [`Webkit${capKey}`]: value,
        [`ms${capKey}`]: value,
      }
      break
  }

  return {
    ...objStyles,
    [key]: value,
  }
}

export const useThemeUISetValue = (k, deps = [], opts = {}) => {
  const { theme } = useThemeUI()

  return useMemo(
    () => setValue(k, theme, opts),
    [theme, k, ...(deps ? deps : [])]
  )
}

export const useThemeUISetValueForScreenWidth = (k, deps = [], opts = {}) => {
  const { theme } = useThemeUI()
  const { width: screenWidth } = useScreenSize()

  return useMemo(
    () => setValueForScreenWidth(k, theme, screenWidth, opts),
    [theme, k, screenWidth, ...(deps ? deps : [])]
  )
}

export const useValueForScreenWidth = (v, deps = []) => {
  const { theme } = useThemeUI()
  const { width: screenWidth } = useScreenSize()

  return useMemo(
    () => valueForScreenWidth(v, theme, screenWidth),
    [theme, v, screenWidth, ...(deps ? deps : [])]
  )
}

export const setValue = (k, theme, _opts = {}) => {
  const opts = {
    parse: false,
    ..._opts,
  }
  const { parse } = opts

  let setValue = valueFromTheme('setValues', k, theme, opts)
  if (setValue && isObject(setValue) && setValue.property && setValue.value) {
    if (parse) {
      const setValueProperty = mapPropertyToThemeUIKey(setValue.property)
      setValue = fnOnThemeValue(setValue.value, (_v) =>
        valueFromTheme(setValueProperty, _v, theme, opts)
      )
    } else {
      setValue = setValue.value
    }
  }

  return setValue
}

export const setValueForScreenWidth = (k, theme, screenWidth, _opts = {}) => {
  const opts = {
    parse: true,
    numerical: true,
    ..._opts,
  }
  const { numerical } = opts
  const values = setValue(k, theme, opts)
  const value = valueForScreenWidth(values, theme, screenWidth)
  if (numerical) {
    const [v, u] = getValueAndUnit(value)
    return stripUnit(valueFnForScreenWidth(v, u, screenWidth))
  } else {
    return value
  }
}

export const valueForScreenWidth = (value, theme, screenWidth = null) => {
  if (!isArray(value)) {
    return value
  }
  const breakpointIndex = breakpointIndexForScreenWidth(screenWidth, theme)
  if (breakpointIndex >= value.length) {
    return value[value.length - 1]
  } else {
    let v = null
    for (let i = breakpointIndex; i >= 0; i--) {
      const _v = value[i]
      if (!isNull(_v)) {
        v = _v
        break
      }
    }
    return v
  }
}

const breakpointIndexForScreenWidth = (screenWidth, theme) => {
  const breakpoints = get(theme, 'breakpoints')
  if (!breakpoints || !isArray(breakpoints)) {
    return null
  }
  let index = null
  for (let i = 0; i < breakpoints.length; i++) {
    const _v = stripUnit(breakpoints[i])
    if (screenWidth < _v) {
      index = i
      break
    }
  }
  return isFinite(index) ? index : breakpoints.length
}

export const mathOnCss = (v, calc) => {
  if (isFunction(calc)) {
    const [value, unit] = getValueAndUnit(v)
    return `${calc(value)}${unit}`
  } else {
    return v
  }
}

const valueFnForScreenWidth = (v, u, sW) => {
  switch (u) {
    case 'vw':
      return vwToPx(v, sW)
    default:
      return v
  }
}

export const pxToVW = (px, base = 1440) => {
  const vwContext = math(`${base} * 0.01px`)
  const vw = math(`${px} / ${vwContext}`)
  return `${stripUnit(vw)}vw`
}

export const vwToPx = (vw, width) => {
  return math(`${vw} * ${isFinite(width) ? width : 1} * 0.01px`)
}

export const adobeSpacing = (spacing = 0) => {
  return `${spacing / 1000}em`
}

export const getVW = (targetSize, baseSize = 1440) => {
  const vwContext = math(`${baseSize} * 0.01 * 1px`)
  const vw = math(`${targetSize} / ${vwContext}`)
  return `${stripUnit(vw)}vw`
}

export const mapVW = (arr, base) => {
  return arr.map((_v) => {
    return _v ? getVW(_v, base) : null
  })
}

export const percForProps = ({ across, base }) => {
  if (!isArray(across) && !isArray(base)) {
    return calcWidth(across, base)
  } else {
    const length = Math.max(
      isArray(across) ? across.length : 1,
      isArray(base) ? base.length : 1
    )
    const arr = Array(length)
      .fill(null)
      .map((_o, _i) => {
        let _v = null
        const [aV, aPV] = getResponsiveArrayValue(across, _i)
        const [bV, bPV] = getResponsiveArrayValue(base, _i)

        if (isNull(aV) && isNull(bV)) {
          _v = null
        } else if (!isNull(aV) && !isNull(bV)) {
          _v = calcWidth(aV, bV)
        } else {
          _v = calcWidth(aV, bV)
        }

        return _v
      })
    return arr
  }
}

const getPreviousArrayValue = (o, i) => {
  // TO DO
}

const getResponsiveArrayValue = (o, i) => {
  if (!isArray(o)) {
    return [o, o]
  } else {
    const _i = Math.min(o.length, i)
    const _v = o[_i]
    return [_v, isNull(_v) ? getPreviousArrayValue(o, _i) : _v]
  }
}

const calcWidth = (a, b) => {
  const _a = isFinite(a) ? a : 1
  const _b = isFinite(b) ? b : 1
  return `${(_a / _b) * 100}%`
}
