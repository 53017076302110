/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import useDimensions from 'react-cool-dimensions'
import { GatsbyImage } from 'gatsby-plugin-image'
import { fill, isFinite } from 'lodash'
import { css } from '@emotion/react'

import { fitDimensionsToBox } from '../../lib/math-helpers'
import { fillArea } from '../../styles/css'
import { crossBrowserStyle } from '../../styles/utils'

const calcDimensions = (image, boxDimensions, contain) => {
  return fitDimensionsToBox(
    {
      width: Math.ceil(boxDimensions.width) + 1,
      height: Math.ceil(boxDimensions.height) + 1,
    },
    {
      width: image && image.width ? image.width : null,
      height: image && image.height ? image.height : null,
      ratio:
        image && image.aspectRatio && isFinite(image.aspectRatio)
          ? 1 / image.aspectRatio
          : null,
    },
    contain
  )
}

export const FillContainer = (props) => {
  const {
    size,
    alignX,
    alignY,
    contain,
    children,
    containedEl,
    ...otherProps
  } = props
  const { observe, width: boxWidth, height: boxHeight } = useDimensions()

  const { width, height } = calcDimensions(
    size,
    { width: boxWidth, height: boxHeight },
    contain
  )

  const alignHorizontal = alignX === 'left' || alignX === 'right'
  const alignVertical = alignY === 'top' || alignY === 'bottom'

  return (
    <div
      sx={{
        overflow: 'hidden',
      }}
      css={css`
        ${fillArea}
      `}
      ref={observe}
      {...otherProps}
    >
      <div
        sx={{
          position: 'absolute',
          left: alignX === 'left' ? 0 : alignX === 'right' ? null : '50%',
          top: alignY === 'top' ? 0 : alignY === 'bottom' ? null : '50%',
          right: alignX === 'right' ? 0 : null,
          bottom: alignY === 'bottom' ? 0 : null,
        }}
        style={
          isFinite(width) && isFinite(height)
            ? {
                width,
                height,
                ...crossBrowserStyle(
                  'transform',
                  `translate3d(
                    ${alignHorizontal ? 0 : `${width * -0.5}px`},
                    ${alignVertical ? 0 : `${height * -0.5}px`},
                  0)`
                ),
              }
            : null
        }
      >
        {containedEl ? containedEl : null}
        {children}
      </div>
    </div>
  )
}

const ImageFill = (props) => {
  const {
    image,
    alt,
    onImageLoad,
    gatsbyImageProps: _gatsbyImageProps,
    ...otherProps
  } = props

  const gatsbyImageProps = _gatsbyImageProps ? _gatsbyImageProps : {}

  return (
    <FillContainer size={image} {...otherProps}>
      {image && image.src ? (
        <img
          src={image.src}
          width={image.width}
          height={image.height}
          sx={{
            display: 'block',
            width: '100%',
            height: '100%',
          }}
        />
      ) : image ? (
        <GatsbyImage
          image={image}
          style={{ width: '100%', height: '100%' }}
          alt={alt ? alt : ''}
          onLoad={onImageLoad}
          {...gatsbyImageProps}
        />
      ) : null}
    </FillContainer>
  )
}

export default ImageFill
